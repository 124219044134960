#salesOuterWrapper {
	display: flex;
	flex-direction: column;
	height: 100% !important;

	.userLayoutWrapper {
		display: flex;
		flex-direction: row;
		height: 100%;
		overflow: hidden;
		position: relative;

		.layoutLeftWrapper {
			&.autoClose {
				+.contentMainWrapper {
					margin-left: 64px;
					width: calc(100vw - 64px);
				}
			}

			&:not(.autoClose) {
				+.contentMainWrapper {
					width: calc(100vw - 240px);
				}
			}

			+.contentMainWrapper {
				margin-left: 240px;
				transition: all .2s ease-out;
			}
		}

		.contentMainWrapper {
			display: flex;
			flex: 1 1 100%;
			flex-direction: column;

			.contentBottomWrapper {
				display: flex;
				flex: 1 1 100%;
				flex-direction: column;
				overflow: auto;

				&:has(.contentBottomMainCenter>.fullWidth) .contentBottomMainCenter,
				&:has(.contentBottomMainCenter>.fullWidth) .contentBottomTopWrapper {
					max-width: 2000px;
				}

				.contentBottomTopWrapper {
					align-items: stretch;
					align-self: center;
					display: flex;
					flex-direction: column;
					gap: 16px;
					justify-content: space-between;
					max-width: 1330px;
					padding: 32px 65px;
					width: 100%;

					.title {
						color: var(--text-standard);
						flex: 1;
						padding-bottom: 0;

						h1 {
							font-weight: 800;
						}
					}

					.rsTabsWrapper{
						color: var(--text-light);
						display: flex;
						flex: 1;
						min-width: 0;
						.rsTab{
							border-bottom: 1px solid var(--border-grey-light);
							color: var(--text-light);
							padding: 10px 20px;
							cursor: pointer;
							&.active{
								border-bottom: 3px solid var(--primary-color);
								color: var(--text-standard);
								font-weight: 700;
							}
						}						
					}
					.rsTabButtons{
						align-self: stretch;
						display: flex;
					}
				}

				.contentBottomMainContainer {
					display: flex;
					flex: 1 1 100%;
					flex-direction: column;
					z-index: auto;
					align-items: stretch;

					.contentBottomMainCenter {
						align-self: center;
						display: flex;
						flex: 1 1 100%;
						flex-direction: column;
						max-width: 1330px;
						padding: 0 65px 32px;
						width: 100%;

						.dashboardPageSection {
							.dashboardActionShortcut {
								>.titleShortcut {
									align-items: center;
									background: var(--white);
									border: 1px solid var(--border-grey-light);
									border-radius: 10px;
									display: flex;
									flex: 1;
									gap: 16px;
									padding: 16px 24px 16px 16px;

									&:hover {
										background: var(--background-grey-medium);
										cursor: pointer;
									}

									>.contentCenter {
										display: flex;
										flex: 1;
										flex-direction: column;
										gap: 4px;

										>.title {
											font-size: 16px;
											font-weight: 600;
										}

										>.description {
											color: var(--text-light);

											.disconnected {
												color: var(--alert);
												font-weight: 500;
											}

											.connected {
												color: var(--success);
												font-weight: 500;
											}
										}
									}

									>.separator {
										background-color: var(--border-grey-light);
										height: 100%;
										width: 1px;
									}

									>i {
										font-size: 16px;
									}
								}
							}

							.block {
								&.dashboardChecklist {
									background: var(--white);
									border: 1px solid var(--border-grey-light);
									border-radius: 10px;
									padding: 16px;

									.header {
										align-items: flex-start;
										display: flex;
										justify-content: space-between;

										&:not(:last-child) {
											margin-bottom: 20px;
										}

										.block-title {
											color: var(--text-light);
											font-weight: 500;
										}
									}

									.uiSection {
										background: var(--background-grey-light);
										border: 1px solid var(--border-grey-light);

										&.sectionChecked {
											background-color: var(--success-100);

											.btn.btn-secondary {
												background-color: transparent;
												box-shadow: inset 0 0 0 1px var(--success-500);
												color: var(--success-500);
											}
										}

										.header {
											&.bordered {
												border-bottom: 1px solid var(--border-grey-light);
											}

											padding: 16px;

											.title {
												align-items: center;
												color: var(--text-standard);
												display: flex;
												font-weight: 600;
												gap: 6px;

												.checkContainer {
													align-self: flex-start;
													margin-right: 10px;

													i.fa-circle-check {
														color: var(--success);
													}

													.checkedStep {
														align-items: center;
														background-color: var(--primary-color-100);
														border-radius: 50%;
														color: var(--primary-color);
														display: flex;
														height: 25px;
														justify-content: center;
														width: 25px;
													}
												}
											}
										}

									}

									.content {
										align-items: center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.onboardModal {
	max-width: unset;
	width: 650px;
	.modal-content {
		border-bottom: 5px solid var(--primary-color);
		max-height: 810px;

		.modal-body {
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: initial;
			padding: 0;
			width: 100%;

			.header {
				border-bottom: 1px solid var(--border-grey-light);
				flex: 0 0 auto;
				margin: 0 24px;
				padding: 24px 0;
			}

			.onboardModalContent {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
				gap: 32px;
				overflow: auto;
				padding: 24px;

				h2 {
					margin-top: 16px;
				}

				.btn-tertiary {
					display: inline-block;
					text-align: right;
				}

				.connectAuth {
					align-items: flex-end;
					background: var(--background-grey-medium);
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: 180px 40px;
					border: 1px solid var(--border-grey-light);
					border-radius: 10px;
					box-shadow: 0 0 6px rgba(0, 0, 0, .02), 0 2px 4px rgba(0, 0, 0, .08);
					display: flex;
					flex: 0 0 293px;
					height: 200px;
					justify-content: end;
					padding: 16px;

					&.gmail {
						background-image: url("../images/gmail-small.svg");
					}

					&.outlook {
						background-image: url("../images/office-365-small.svg");
					}
				}
			}
		}
	}
}

.accountSettingModal {
	height: 800px;
	min-width: 1130px;

	.loader{
		align-items: center;
		display: flex;
		height: 100px;
		justify-content: center;
		width: 100px
	}

	@media(min-width: 567px) {
		max-width: 80%;
	}

	@media(min-width: 567px) {
		max-width: 1200px;
	}

	@media (max-height: 850px) {
		height: 700px;
	}

	.modal-content {
		margin: auto;
		width: 980px;
		background-color: var(--white);
		border: none;
		border-radius: 10px;
		box-shadow: 0 0 4px rgba(0, 0, 0, .04), 0 8px 16px rgba(0, 0, 0, .08), 0 4px 0 var(--primary-color);
		height: 100%;
		max-height: 700px;
		position: relative;

		.modal-body {
			border-radius: 10px;
			display: flex;
			flex-direction: row;
			height: 100%;
			overflow: hidden;
			padding: 0;

			hr {
				border-top: 1px solid var(--border-grey-light);
				margin: 0;
				width: 100%;
			}

			.accountSettingModalLeftWrapper {
				background: var(--background-grey-light);
				border-right: 1px solid var(--border-grey-light);
				display: flex;
				flex: 0 0 300px;
				flex-direction: column;
				gap: 32px;
				padding-top: 32px;

				.modalLeftHeading {
					font-size: 20px;
					font-weight: 600;
					padding: 0 32px;
				}

				.modalLeftItemsWrapper {
					display: flex;
					flex: 1;
					flex-direction: column;

					.modalLeftItemWrap {
						align-items: center;
						cursor: pointer;
						display: flex;
						font-size: 16px;
						font-weight: 500;
						gap: 8px;
						height: 55px;
						padding: 16px 16px 16px 32px;
						position: relative;

						i {
							width: 24px;
							font-size: 16px;
							text-align: center;
						}

						&.active:not(:disabled) {
							background: var(--primary-color-100);
							box-shadow: inset 5px 0 0 0 var(--primary-color);
							color: var(--primary-color);
							position: relative;
						}
					}
				}
			}

			.accountSettingModalRightWrapper {
				display: flex;
				flex: 1;
				flex-direction: column;
				height: 100%;
				overflow: hidden;
				position: relative;

				.userSettingModalRightTopWrap {
					border-bottom: 1px solid var(--border-grey-light);
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 0 32px;
					padding: 32px 0 16px;

					.modalRightTitle {
						align-items: center;
						align-self: start;
						display: flex;
						font-size: 20px;
						font-weight: 600;
						gap: 8px;
						justify-content: center;
					}

					.btnModalClose {
						background: var(--white);
						border: 1px solid var(--border-grey-light);
						border-radius: 5px;
						color: var(--text-standard);
						cursor: pointer;
						height: 32px;
						padding: 8px;
						width: 32px;
						position: absolute;
						right: 16px;
						top: 16px;
						box-shadow: none;
					}
				}

				.userSettingModalCenterWrap {
					display: flex;
					flex: 1;
					flex-direction: column;
					overflow: hidden;
					overflow-y: auto;
					padding: 32px 32px 16px;

					.accountIdentityContainer {
						.accountIdentity {
							max-width: 90%;

							.accountIdentityName {
								>* {
									flex-grow: 1;
								}
							}
						}
					}
				}

				.modalButtons {
					border-top: 1px solid var(--border-grey-light);
					padding: 16px 32px;
				}
			}

			.emailSignature{
				display: flex;
				flex: 1;
				flex-direction: column;
				gap: 16px;
			}
		}
	}
}

.signatureTextEditor {
	/* Make the editor container relative so we can position children inside it */
	// .quill {
	// 	position: relative;
	// 	height: 400px; /* Or any height you prefer */
	// 	overflow: hidden;
	// }

	/* Position the toolbar at the bottom */
	// .ql-toolbar.ql-snow{
	// 	position: absolute;
	// 	bottom: 0;
	// 	width: 100%;
	// }

	/* Adjust the content area to not be obscured by the toolbar */
	// .ql-snow .ql-editor {
	// 	height: calc(100% - 66px); /* 42px is an example height of the toolbar. Adjust accordingly. */
	// 	overflow-y: scroll;
	// }

	.quill {
		.ql-container.ql-snow {
			height: 390px;
			border: 1px solid var(--border-grey-light);
			border-bottom: none;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}

	.ql-toolbar.ql-snow {
		border: 1px solid var(--border-grey-light);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		// display: flex;
		// align-items: center;
		background: var(--white);
		box-shadow: 0 -4px 20px rgba(0, 0, 0, .05);
		font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
		padding: 8px 16px;
		position: relative;
		z-index: 1;

		button {
			background: transparent;
			color: #333333;
			-moz-outline: 0;
			outline: none;
			border: 0;
			line-height: 1;
			cursor: pointer;
			text-align: left;
			margin: 4px 2px;
			padding: 0;
			-webkit-transition: all 0.5s;
			-moz-transition: all 0.5s;
			-ms-transition: all 0.5s;
			-o-transition: all 0.5s;
			transition: all 0.5s;
			border-radius: 4px;
			-moz-border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-background-clip: padding;
			-webkit-background-clip: padding-box;
			background-clip: padding-box;
			z-index: 2;
			position: relative;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			text-decoration: none;
			user-select: none;
			-o-user-select: none;
			-moz-user-select: none;
			-khtml-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			height: 40px;
			width: 38px;
			text-align: center;
			border: 0;
			box-shadow: none;

			&#addPersonalizationButton{
				border: 1px solid var(--border-grey-light);
				border-radius: 10px !important;
				color: var(--primary-color);
				padding: 8px 16px;
				width: auto;
			}

			&#emailPreviewButton{
				align-items: center;
				display: flex;
				gap: 8px;
				width: auto;
				span{
					font-weight: 500;
					margin: 0;
					text-decoration: underline;
					width: unset!important;					
				}
				&::before{
					content: "\f06e";
					font-family: "Font Awesome 6 Free";
					font-weight: 400;
				}
			}

			svg {
				height: auto;
				width: 26px;
				float: none;
			}
		}

		.ql-formats {
			margin-right: 0;
		}
	}
}

.campaignsList {
	color: var(--text-light);
	height: 100%;

	.rsSearchInput {
		width: 400px;
	}

	.rsTable {
		height: 100%;

		&.campaignsList {
			.header {
				background: unset;
				border-radius: 10px 10px 0 0;
				color: var(--text-standard);
				padding: 10px 16px;
			}
		}

		.header {
			background-color: var(--background-grey-light);
			border-bottom: none;
			border-radius: 10px 10px 0 0;
			color: var(--text-standard);
			font-weight: 600;
			height: 36px;
			align-items: center;
			border: 1px solid var(--border-grey-light);
			font-size: 12px;

			.rsSortable:not(.allow-wrap)>span,
			&>.headerItem:not(.allow-wrap):not(.rsSortable) {
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.rsSortable {
				align-items: center;
				cursor: pointer;
				display: flex;
				gap: 8px;

				&::after {
					font-weight: 900;
					padding-left: 4px;
					content: "\f0d7";
					font-family: "Font Awesome 6 Free";
				}
			}
		}

		.header,
		.tableRow {
			column-gap: 20px;
			cursor: pointer;
			display: grid;
			grid-template-columns: 65px 1fr 110px 60px 135px 110px 95px 70px;
			background: var(--white);

			@media (max-width: 1440px) {
				grid-template-columns: 65px 1fr 110px 60px 135px 50px 95px 70px;
			}
		}

		.tableRows {
			border: 1px solid var(--border-grey-light);
			border-top: 0;

			.tableRow {
				font-weight: 500;
				height: 50px;
				padding: 10px 16px;

				&:first-child {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}

				&:last-child {
					border: 0;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
				}

				>.tableRowItem {
					max-width: 100%;
					overflow-x: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				>.tableRowItemFlex {
					align-items: center;
					display: flex;
					gap: 8px;
					justify-self: flex-start;
				}

				:not(.archived) {
					.campaignName {
						color: var(--text-standard);
					}
				}

				&.archived{
					background: var(--background-grey-medium);
					color: var(--text-inactive);
				}

				.campaignName {
					align-items: center;
					display: flex;
					gap: 8px;

					.name {
						height: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.campaignLeadsCount {
					padding: 2px 4px;
					white-space: nowrap;
				}

				.campaignSenders {
					display: flex;

					.avatar {
						font-size: 14px;
						height: 30px;
						min-width: 30px;
						width: 30px;

						.picture {
							height: 30px;
							width: 30px;
						}
					}
				}

				.campaignGroups {
					pointer-events: none;

					.group {
						background: var(--primary-color-100);
						border-radius: 5px;
						color: var(--primary-color);
						font-weight: 600;
						padding: 8px 5.5px;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.campaignCreatedBy {
					justify-content: center;

					.avatar {
						font-size: 14px;
						height: 30px;
						min-width: 30px;
						width: 30px;

						.picture {
							height: 30px;
							width: 30px;
						}
					}
				}

				.campaignCreatedAt {
					.fromNow {
						font-size: 14px;
						text-decoration: none;
					}
				}

				.dropdownTrigger {
					display: inline;
				}
			}

			.emptyPlaceholder {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
				background: var(--white);
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				padding: 40px 0;
			}
		}
	}

	.tableRows {
		border-radius: 0 0 10px 10px;
	}
}

.dropdownTrigger {
	&.show {
		.campaignFilter {
			border: 1px solid var(--primary-color);
			box-shadow: 0 0 0 3px rgba(49, 107, 255, .15);
		}
	}

	.campaignFilter {
		align-items: center;
		background-color: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;
		color: var(--text-standard);
		display: flex;
		gap: 8px;
		line-height: 20px;
		padding: 12px 16px;

		&:hover {
			border: 1px solid var(--primary-color);
			box-shadow: 0 0 0 3px rgba(49, 107, 255, .15);
		}

		.pastille {
			align-items: center;
			background-color: #ec6181;
			border-radius: 5px;
			color: var(--background-grey-light);
			font-size: 11px;
			font-weight: 700;
			justify-content: center;
			padding: 3px 6px;
		}
	}

	.campaignFilterDropdownMenu {
		box-shadow: 0 0 4px rgba(0, 0, 0, .04), 0 8px 16px rgba(0, 0, 0, .08);
		border-color: var(--border-grey-light);
		margin-top: 8px;		
		padding: 0;
		&.singleItem{
			padding: 0;
			.dropdownRSMenuItems{
				.dropdownRSMenuItem{
					padding: 16px;
				}
			}
		}

		&.scheduleDropdown{
			.dropdownRSMenuItems{
				width: 215px;
			}
		}

		&.startSteps{
			.dropdownRSMenuItems{
				width: 220px;
			}
		}

		&.stepDropdownMenu{
			&.reviewSequenceLead{
				.dropdownRSMenuItems{
					width: 145px;
				}
				&.withReset{
					.dropdownRSMenuItems{
						width: 176px;
					}
				}
			}

			.dropdownRSMenuItems{
				width: 175px;
			}
			&.withABTest{
				.dropdownRSMenuItems{
					width: 186px;
				}
			}

			&.reviewAllButtons{
				.dropdownRSMenuItems{
					width: 215px;
				}
			}

			&.withStepVariation{
				.dropdownRSMenuItems{
					width: 230px;
				}
			}
			
			&.withWait{
				.dropdownRSMenuItems{
					width: 305px;
				}
			}
		}

		.campaignFilters {
			width: 260px;
			color: var(--text-standard);

			.fa-circle-exclamation {
				color: var(--alert);
			}

			.rsSearchInput {
				background-color: var(--background-grey-light);
				margin: 8px 8px 0;
				width: auto;
			}

			.items {
				display: flex;
				flex-direction: column;
				gap: 16px;
				max-height: 300px;
				overflow-y: scroll;
				padding: 16px;

				.item {
					align-items: center;
					display: flex;
					gap: 8px;
				}
			}

			.clearFilters {
				align-items: center;
				background: var(--background-grey-light);
				border-top: 1px solid var(--border-grey-light);
				cursor: pointer;
				display: flex;
				justify-content: center;
				padding: 11px 0;
			}
		}
	}
}

.dropdownRSMenuItems {
	display: flex;
	flex-direction: column;
	padding: 0;
	font-size: 14px;
	width: 190px;

	.dropdownRSMenuItem {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-weight: 500;
		gap: 16px;
		padding: 8px 16px;

		&.disabled{
			cursor: not-allowed;
			opacity: .6;
			pointer-events: none;
		}

		&.alert-300 {
			color: var(--alert);
		}

		&.separator {
			&:not(:first-child) {
				border-top: 1px solid var(--border-grey-light);
			}
		}

		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding-top: 16px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			padding-bottom: 16px;
		}

		i {
			font-size: 16px;
			text-align: center;
			width: 1.25em;
		}
		svg{
			width: 22px;
			height: auto;
		}

		.text {
			flex: 1;
			text-align: left;
		}
	}
}

.rsSwitch {
	&.customControl {
		min-height: unset;
		padding: 0;		

		input {
			opacity: 0;
			position: absolute;

			&:checked {
				~.switchDisplay {
					background: var(--primary-color);

					&::after {
						animation-name: on;
						left: calc(100% - 18px);
						right: 2px;
					}
				}
				&:disabled{
					~.switchDisplay{
						background-color: #d6d6d6;
					}
				}
			}
		}

		.switchDisplay {
			background: #d6d6d6;
			border: transparent;
			border-radius: 10px;
			display: block;
			height: 20px;
			left: 0;
			position: absolute;
			top: 0;
			transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
			width: 38px;

			&::after {
				animation-duration: .2s;
				animation-name: off;
				background: no-repeat 50%/50% 50%;
				background-color: var(--white);
				border-radius: 50%;
				content: "";
				display: block;
				height: calc(100% - 4px);
				left: 2px;
				position: absolute;
				right: calc(100% - 18px);
				top: 2px;
			}
		}
	}

	.customControlLabel {
		cursor: pointer;
		line-height: 20px;
		padding-left: 38px;
		&.hasLabel{
			padding-left: 48px;
		}
		input{
			&:checked{
				~.rsLabel{
					font-weight: 600;
				}
			}
		}
	}
}

.campaignEditPage {
	display: flex;
	flex-direction: column;
	height: 100vh;
	left: 0;
	top: 0;
	width: 100vw;

	.campaignTopBarMenu {
		background: var(--white);
		border-bottom: 1px solid var(--border-grey-light);
		box-shadow: 0 0 6px rgba(0, 0, 0, .02), 0 2px 4px rgba(0, 0, 0, .08);
		display: flex;
		height: 69px;

		.campaignEditCloseBtn {
			align-items: center;
			border-right: 1px solid var(--border-grey-light);
			display: flex;
			padding: 0 16px;

			.rsCloseCampaignEditor {
				align-items: center;
				border-radius: 10px;
				color: var(--text-inactive);
				cursor: pointer;
				display: flex;
				justify-content: center;
				padding: 8px;

				i {
					font-size: 16px;
					text-align: center;
				}
			}
		}

		.campaignTopBarContent {
			flex: 1;
			padding: 8px 24px;

			.campaignNameWrapper {
				flex: 1;

				.inputWrapper {
					height: 42px;
				}
			}

			.campaignTabsBarContent {
				align-items: center;
				display: flex;
				gap: 8px;
				justify-content: center;
				padding: 5px 0;

				.tab {
					align-items: center;
					border-radius: 8px;
					color: var(--primary-color);
					display: flex;
					font-size: 16px;
					font-weight: 500;
					gap: 8px;
					height: 42px;
					justify-content: center;
					padding: 8px 20px;
					position: relative;

					&.active {
						background-color: var(--primary-color-100);
					}

					&.disabled{
						color: var(--text-inactive);
						pointer-events: none;
						.numberCircle{
							border-color: var(--text-inactive);
						}
					}

					.reviewCount{
						background: var(--secondary-color);
						border-radius: 5px;
						color: var(--white);
						font-size: 11px;
						font-weight: 600;
						padding: 1px 6px;
					}

					.numberCircle {
						width: 16px;
						height: 16px;
						display: flex;
						font-size: 12px;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						border: 1px solid var(--primary-color);

					}
				}
			}
		}
	}

	.campaignEditContentWrapper {
		display: flex;
		flex: 1;
		justify-content: center;
		overflow-y: auto;

		&.rsEditorMainWrapper{
			&.tabSequence {
				&.fullscreen{
					>.center{
						flex-grow: 0.00001;
						min-width: 0;
						transform: translateX(100%);
					}
				}
				>.center {
					background: var(--white);
					box-shadow: 0 0 4px rgba(0,0,0,.04), 0 8px 16px rgba(0,0,0,.08);
					flex: 1.5;
					max-width: 1200px;
					min-width: 600px;
					z-index: 2;
					.sequenceEditor {
						&.newSequenceMenu {
							align-items: center;
							flex: 1;
							justify-content: start;
							padding: 0 32px;
						}
					}
				}
			}
		}

		&.backgroundDotted {
			background: var(--background-dotted);
		}

		&.tabSequence {

			>.left {
				flex: 0 0 680px;
				width: 680px;
				flex: 2;

				.content {
					overflow-y: hidden;
					padding: 0;
				}
			}
			.center,
			.left {
				transition: transform .3s ease-in-out, min-width .3s ease-in-out, flex-basis .3s ease-in-out;
			}

		}

		>.left {
			display: flex;
			flex: 0 0 615px;
			flex-direction: column;
			overflow-y: auto;
			position: relative;
			width: 470px;
			z-index: 2;

			.content {
				display: flex;
				flex: 1;
				overflow-y: scroll;
				padding: 32px 24px 0;
			}

			> .bottom{
				width: 100%;
			}

			.bottom{
				border-top: 1px solid var(--border-grey-light);
				box-shadow: 0 -4px 20px rgba(0,0,0,.05);
				display: flex;
				flex-direction: column;
				padding: 16px;
				position: relative;
			}
		}

		> .center{
			margin: 0;
		}

		.center{
			display: flex;
			min-width: 0;
			width: 1440px;
			&.fullWidth{
				width: 100%;
			}
		}

		&.tabReview{
			& > .left{
				background: var(--white);
				box-shadow: 0 0 4px rgba(0,0,0,.04), 0 8px 16px rgba(0,0,0,.08);
				flex: 0 0 500px;
				width: 500px;

				.top{
					padding: 24px;
					width: 100%;
					.editorTabReviewLeftTop{
						display: flex;
						height: 45px;
						justify-content: space-between;
						width: 100%;

						.leadReviewMain{
							align-items: center;
							display: flex;
							width: 100%;

							.displayNumberSelectedLeads{
								white-space: nowrap;
								padding-left: 5px;
							}

							.rsSearchLead{
								flex: 1;
								width: 0;
								margin-left: 10px;
								opacity: 0;
								transition: all .3s cubic-bezier(.68,-.55,.265,1.55);
								z-index: -1;
								&.show{
									opacity: 1;
									width: 100%;
									z-index: 1;
								}
							}
						}

						.leadReviewActions{
							align-items: center;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
				.content{
					margin: 0;
					overflow: hidden;
					padding: 0;
					width: 100%;
					display: flex;
					flex: 1;
					.editorTabReviewLeft{
						margin: 0;
						overflow-y: auto;
						padding: 0 24px 24px;
						flex: 1;
						.listLeads{
							flex: 1;
							overflow: initial;

							.leadLeft{
								border: 1px solid var(--border-grey-light);
								border-radius: 10px;
								cursor: pointer;
								padding: 8px 16px;
								&.active{
									background-color: var(--primary-color-100);
									border-color: var(--primary-color);
								}
								.leadReviewSummaryCard{
									flex: 1;
									overflow: hidden;
								}
							}
						}
					}
				}
			}

			& > .center{
				flex: 1;
				overflow-y: auto;
				position: relative;
			}
		}
	}
}

.stepLabel {
	align-items: center;
	display: flex;
	gap: 8px;
	user-select: none;

	&.disabled {
		background: var(--background-disable);
		cursor: default !important;
		filter: grayscale(100%);
		pointer-events: none;
	}

	.desc {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.labelType {
		font-weight: 500;
	}

	.descriptionType {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.fa-linkedin {
			color: #3077b0;
			font-size: 16px;
			text-align: center;
			width: 1.25em;
		}

		.descriptionTypeLabel {
			color: var(--text-light);
		}
	}

	.stepError{
		color: var(--alert);
		font-size: 12px;
		font-weight: 500;
	}
}

.newStepMenu {
	background: var(--white);
	border-radius: 8px;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 24px;

	&.padded {
		padding: 8px;
	}

	.rowTypes {
		display: flex;

		&:nth-of-type(2) {
			> :last-child {
				border-bottom-right-radius: 8px;
			}
		}

		&:first-of-type {
			>div {
				border-top: 1px solid var(--border-grey-light);

				&:first-child {
					border-top-left-radius: 8px;
				}

				&:last-child {
					border-right: 1px solid var(--border-grey-light);
					border-top-right-radius: 8px;
				}
			}

			&:last-of-type {
				>div {
					&:last-of-type {
						border-bottom-right-radius: 8px;
					}
				}
			}
		}

		&:last-of-type {
			>div {
				&:first-child {
					border-bottom-left-radius: 8px;
				}
			}
		}

		>div {
			border-bottom: 1px solid var(--border-grey-light);
			border-right: 1px solid var(--border-grey-light);
			cursor: pointer;
			flex: 1;
			padding: 8px;

			&:first-of-type {
				border-left: 1px solid var(--border-grey-light);
			}
		}
	}

	.stepLabel {
		white-space: nowrap;
	}

	.marginBlockSteps {
		margin-bottom: 60px;
	}
}

.sequenceEditor {

	h4+.subtitle,
	.subtitle {
		color: var(--text-light);
		font-weight: 500;
	}
}

.email{
	border-radius: 4px;
    color: #414141;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.6;
}

.channelIcon {
	border-radius: 50%;
	height: 40px;
	min-width: 40px;
	text-align: center;
	width: 40px;

	&.sm{
		height: 32px;
		min-width: 32px;
		width: 32px;
		i{
			line-height: 32px;
		}
	}

	&.email {
		background: var(--tertiary1-color-100);

		i {
			color: var(--tertiary1-color);
		}
	}

	&.conditional,
	&.linkedin {
		background: var(--primary-color-100);
		color: #3077b0;
	}

	&.call,
	&.phone,
	&.manual,
	&.other {
		background: var(--secondary-color-100);

		i {
			color: var(--secondary-color);
		}
	}

	&.api {
		background: var(--primary-color-100);

		i {
			color: var(--primary-color);
		}
	}

	&.delay,
	&.sendToAnotherCampaign {
		background: var(--background-grey-medium);

		i {
			color: var(--text-light);
		}
	}

	i {
		line-height: 40px;
	}
}

.sequenceEditorLeft {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
}

.rsTreeNavigation {
	padding: 16px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	.containerActions{
		align-items: center;
		border: 1px solid var(--border-grey-light);
		border-radius: 5px;
		box-shadow: 0 0 4px rgba(0,0,0,.04), 0 4px 8px rgba(0,0,0,.06);
		display: flex;
		height: 36px;
		justify-content: center;

		.rsAction{
			align-items: center;
			background: var(--white);
			color: var(--text-standard);
			cursor: pointer;
			display: flex;
			flex: 1;
			height: 100%;
			justify-content: center;
			padding: 16px 8px;
			&:first-child{
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}
			&:last-child{
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
			}
		}
	}
}

.rsTree{
	cursor: grab;
	display: flex;
	height: 100%;
	overflow: auto;
	padding: 0 16px;

	.rsTreeDelayEditor{
		padding: 16px;
		width: 320px;
		.text{
			font-weight: 500;
			font-size: 14px;
		}
	}

	.steps{
		align-items: center;
		cursor: grab;
		display: flex;
		flex: 1;
		padding-top: 16px;
		transform-origin: top;
		transition: transform .3s;

		> .rsTreeSequence{
			padding-bottom: 32px;
			align-items: center;
			display: flex;
		}
	}

	.rsTreeStart,
	.rsTreeDelay,
	.rsTreeSequenceStep{
		background: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.08), 0 0 6px 0 rgba(0,0,0,.02);
	}

	.rsTreeStart,
	.rsTreeDelay,
	.rsTreeSequenceStep,
	.stepVerticalSeparator{
		width: 322px;
	}

	.stepVerticalSeparatorLine{
		align-self: center;
		border-left: 1px solid var(--text-inactive);
		height: 15px;
		width: 0;
	}

	.rsTreeStart{
		align-items: center;
		color: var(--text-inactive);
		display: flex;
		height: 60px;
		justify-content: center;
		padding: 16px;

		:has(.dropdownTrigger){
			.label{
				padding-left: 32px;
			}
		}

		.label{
			flex: 1;
    		text-align: center;
		}
	}

	.rsTreeSequenceStep{
		border-radius: 10px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		&.active{
			border: 1px solid var(--primary-color);
			box-shadow: 0 0 0 3px rgba(49,107,255,.15);
		}
		&.abTest:has(.error),
		&.error{
			border: 1px solid var(--alert);
			&.active{
				box-shadow: 0 0 0 3px rgba(236,67,67,.15);
			}
		}

		.rsTreeSequenceStepItem{
			align-items: center;
			display: flex;
			flex: 1 1 auto;
			overflow: hidden;
			padding: 16px 4px 16px 16px;
			.stepLabel{
				align-items: center;
				flex: 1 1 auto;
				min-width: 0;
				.stepNumber{
					font-weight: 500;
				}
			}
		}

		.stepError{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.actions{
			align-items: center;
			display: flex;
			gap: 8px;
			padding-right: 16px;
		}

		.abTestStepContainer{
			border-top: 1px solid var(--border-grey-light);
			display: flex;
			flex-direction: column;

			.abTestRow{
				align-items: center;
				background: var(--white);
				border-top: 0;
				display: flex;
				flex: 1;
				gap: 8px;
				padding: 8px 16px;
				&.active{
					background: var(--primary-color-100);
					border: none;
					box-shadow: none;
					color: var(--primary-color);
				}
				&.error{
					border: none;
					padding: 5.5px 8px;
				}

				&:last-of-type{
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
					border-left: 1px solid var(--border-grey-light);
				}

				.contentCenter{
					flex: 1;
				}

				.instructionRequired{
					color: var(--alert);
					font-size: 12px;
					font-weight: 500;
				}
			}
		}

	}

	.rsTreeDelay{
		align-items: center;
    	padding-right: 16px;
		.fa-trash{
			color: var(--text-inactive);
			cursor: pointer;
			padding: 8px;
		}
		.rsTreeDelayContent{
			padding: 16px;
		}
	}
}

.stepVerticalSeparator{
	align-items: center;
	.stepVerticalSeparatorLine{
		border-left: 1px solid var(--text-inactive);
		height: 15px;
	}

	.rsAddStepAtIndex{
		align-items: center;
		background: var(--white);
		border-radius: 100px;
		box-shadow: 0 0 4px rgba(0,0,0,.04), 0 4px 8px rgba(0,0,0,.06);
		display: flex;
		height: 22px;
		justify-content: center;
		width: 22px;
		&:hover{
			cursor: pointer;
			box-shadow: 0 0 4px rgba(0,0,0,.08), 0 4px 8px rgba(0,0,0,.12);
		}
		.fa-plus{
			color: var(--text-inactive);
			font-size: 9px;
		}
	}
}

.multiSenders{
	display: flex;
	.avatar,
	.avatar .picture{
		font-size: 14px;
		height: 30px;
		width: 30px;
	}
	.avatar+.avatar{
		margin-left: -15px;
	}
}

.rsTreeBranches{
	.rsTreeBranchYes,
	.rsTreeBranchNo{
		display: flex;
		flex: 1;
		height: 50px;
	}

	.rsTreeBranchYes{
		.rsTreeBranch{
			.rsTreeBranchLeft{
				align-self: end;
				border-left: 1px solid var(--text-inactive);
				border-top: 1px solid var(--text-inactive);
				border-top-left-radius: 20px;
			}
		}
		.rsTreeBranchRight{
			border-bottom: 1px solid var(--text-inactive);
			border-bottom-right-radius: 20px;
			border-right: 1px solid var(--text-inactive);
		}
	}

	.rsTreeBranchNo{
		.rsTreeBranch{
			.rsTreeBranchLeft{
				border-bottom: 1px solid var(--text-inactive);
				border-bottom-left-radius: 20px;
				border-left: 1px solid var(--text-inactive);
			}
			.rsTreeBranchRight{
				align-self: end;
				border-right: 1px solid var(--text-inactive);
				border-top: 1px solid var(--text-inactive);
				border-top-right-radius: 20px;
			}
		}
	}

	.rsTreeBranch{
		display: flex;
		flex: 1 8px;
		.rsTreeBranchLeft,
		.rsTreeBranchRight{
			height: calc(50% + 0.5px);
			width: 20px;
		}

		.rsTreeBranchCenter{
			align-items: center;
			display: flex;
			flex: 1;
			flex-flow: row;
			justify-content: center;
		}

		hr{
			border-top: 1px solid var(--text-inactive);
			flex: 1;
		}

		.rsTreeBranchLabel{
			align-self: center;
			border-radius: 100px;
			font-size: 12px;
			font-weight: 600;
			padding: 4px 8px;

			&.rsTreeBranchLabelYes{
				background: var(--success-100);
				color: var(--success);
			}

			&.rsTreeBranchLabelNo{
				background: var(--alert-100);
				color: var(--alert);
			}
		}
	}
}

.campaignEditContentWrapper{
	&.tabSequence{
		.center,
		.left{
			overflow-y: auto;
		}
	}
}

.sequenceEditor{
	> .mvpBox{
		&.margins{
			border: none;
			border-bottom: 1px solid var(--border-grey-light);
			border-radius: 0;
			box-shadow: none;
			margin: 0!important;
			padding: 24px;
		}
	}
	
	.editor{
		flex: 1 0 auto;
		gap: 32px;
		padding: 24px;
	}

	.rsDropdown{
		&.rsAssignStepMultiple{
			width: 400px;
		}
	}
}

.stepItem{
	align-items: center;
    display: flex;
    gap: 11px;
	.actions{
		align-items: center;
		display: flex;
		gap: 8px;
	}
}

.stepItem,
.stepItem .stepLabel{
	flex: 1 1 auto;
    min-width: 0;
}

.attributeContainer {
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0,0,0,.04), 0 8px 16px rgba(0,0,0,.08);
    background: var(--white);
    position: absolute;
    top: 100%;
    left: 0;
    height: auto;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    display: block;
    z-index: 999999;
	
	ul{
		margin: 0;
		margin-top: 2px;
		padding: 0;
		list-style: none;
		background: #efefef;

		li{
			padding: 5px 5px;
    		cursor: pointer;
			background-color: var(--white);

			&.highlight{
				background: var(--primary-color-100);
			}
		}
	}
}

.newStepMenu{
	background: var(--white);
	border-radius: 8px;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 24px;
	&.padded{
		padding: 8px;
	}
}

.campaignTabLeads{
	display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    min-width: 0;
    padding: 32px 16px 0;
	&.importMethods{
		align-items: center;
    	justify-content: center;

		.method{
			background-color: var(--white);
			background-position: 28px;
			background-repeat: no-repeat;
			border: 1px solid var(--border-grey-light);
			border-radius: 10px;
			box-shadow: 0 0 6px var(--border-grey-light);
			cursor: pointer;
			display: flex;
			flex-direction: column;
			height: 76px;
			justify-content: center;
			padding-left: 84px;
			position: relative;
			width: 315px;

			&.disabled{
				background-color: var(--background-disable);
				cursor: default !important;
				filter: grayscale(100%);
				pointer-events: none;
			}

			&.api{
				background-image: url(../images/api-big.svg);
				background-position-x: 24px;
			}

			&.zapier{
				background-image: url(../images/zapier.svg);
			}

			&.crm{
				background-image: url(../images/crm-big.svg);
				background-position-x: 32px;
			}

			&.campaign{
				background-image: url(../images/campaign-big.svg);
			}

			&.linkedin{
				background-image: url(../images/linkedin.svg);
			}

			&.manually{
				background-image: url(../images/manually-big.svg);
			}

			&.csv{
				background-image: url(../images/csv-big.svg);
			}

			.importMethodName{
				font-size: 18px;
				font-weight: 600;
			}
		}
	}
	&.list{
		.rsSearchLead{
			width: 420px;
		}
		.dropdownImportNewLeads{
			width: 280px;
			hr{
				margin: 16px;
				width: calc( 100% - 32px );
			}
		}

		.rsTable{
			&.leadsTable{
				overflow-x: scroll;
				.header,
				.tableRows{
					border: none;
					position: relative;
					&::before,
					&::after{
						background: var(--border-grey-light);
						content: " ";
						height: 1px;
						left: 10px;
						position: absolute;
						right: 10px;
						z-index: 1;
					}
					&::before{
						top: 0;
					}

					&::after{
						bottom: 0;
					}
				}

				.header{
					&::after{
						background: var(--border-grey-light);
						content: " ";
						height: 0;
						left: 10px;
						position: absolute;
						right: 10px;
						z-index: 1;
					}
				}

				.header,
				.tableRow{
					position: relative;
					grid-template-columns: 350px 145px 150px 240px 160px minmax(40px,1fr);
				}

				.header,
				.tableRow{
					.stickyEnd,
					.stickyStart{
						align-items: stretch;
						background-image: var(--background-paper)!important;
						background-repeat: repeat;
						display: flex;
						height: 100%;
						// position: sticky;
						// z-index: 100;					
						.stickyContent{
							align-items: center;
							background: var(--white);
							display: flex;
							flex: 1;
							padding-right: 8px;
							width: 100%;
							border-top: 1px solid var(--border-grey-light);
						}
					}

					.stickyStart{
						border-right: 1px solid var(--border-grey-light);
						left: 0;
						margin-left: 0;
						pointer-events: none;
						.stickyContent{
							border-left: 1px solid var(--border-grey-light);
							gap: 8px;
							padding-left: 16px;
						}
					}

					.stickyEnd{
						justify-self: end;
						right: 0;
						.stickyContent{
							border-right: 1px solid var(--border-grey-light);
						}
					}					
				}

				.header{
					background-color: var(--background-grey-light);
					border-bottom: none!important;
					display: inline-grid;
					min-width: 100%;
					padding: 0;

					.stickyContent{
						background-color: var(--background-grey-light)!important;
						border-bottom: none!important;
						border-bottom-left-radius: 0!important;
						border-bottom-right-radius: 0!important;
					}

					.stickyStart{				
						.stickyContent{
							padding-bottom: 8px;
							padding-top: 8px;
							border-top-left-radius: 10px;
						}
					}
					.stickyEnd{
						.stickyContent{
							border-top-right-radius: 10px;
						}
					}
				}

				.tableRows{
					display: inline-block;
					min-width: 100%;
					.tableRow{
						&:hover{
							.stickyEnd,
							.stickyStart{
								.stickyContent{
									background: var(--background-grey-light);
								}
							}
						}
						.tableRowItem{
							&.stickyEnd{
								overflow: initial;
							}
							.columnCompanyName,
							.columnPhone,
							.columnLinkedinUrl{
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
					}
				}

				.tableRow{
					height: 60px;
					padding: 0;
					position: relative;
					&:hover{
						background: var(--background-grey-light);
					}
					&:last-child{
						.stickyEnd,
						.stickyStart{
							.stickyContent{
								border-bottom: 1px solid var(--border-grey-light);								
							}
						}
						.stickyStart{
							.stickyContent{
								border-bottom-left-radius: 10px;
							}
						}
						.stickyEnd{
							.stickyContent{
								border-bottom-right-radius: 10px;
							}
						}
						
					}
					.tableRowItem{
						&:not(.checkbox):not(.moreMenu){
							pointer-events: none;
						}
					}
				}

				.leadSummary{
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			&.importHistory{
				.tableRow{
					grid-template-columns: 20px 280px 1fr 200px 190px 120px 140px;
					height: 40px;
				}
				.tableRow{
					background: var(--white);
					cursor: pointer;
					padding: 0 8px 0 16px;
					.tableRowItem{
						z-index: 2;
						&.importIcon{
							color: var(--text-inactive);
						}
						&.importMethod{
							font-weight: 600;
						}

						&.importActions{
							justify-self: end;
							padding-right: 8px;
							text-align: right;
						}
					}
				}
			}

			.header{
				border-radius: 50px;
				padding-right: 8px;
			}

			.header,
			.tableRow{
				cursor: pointer;
				background-color: var(--white);
			}
		}

		.leadsTable{
			.header,
			.tableRow{
				column-gap: 10px;
				display: grid;
				&.header{
					border-radius: 0;
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}
			}

			.emptyPlaceholder{
				align-items: center;
				display: flex;
				justify-content: center;
				padding-top: 64px;
			}
		}
	}
}

.campaignLeadsImportModal{
	.modal-content{
		.modalCenter{
			padding-bottom: 32px!important;
		}
		.modal-body{
			.modalRight{
				.rsImportArea{
					align-items: center;
					background: var(--background-grey-light);
					border: 1px dashed var(--border-grey-light);
					border-radius: 8px;
					color: var(--text-inactive);
					display: flex;
					flex-direction: column;
					gap: 4px;
					height: 280px;
					justify-content: center;
					position: relative;

					&.allowed{
						color: var(--text-standard);
						cursor: pointer;

						.importButton{
							color: var(--primary-color);
						}
					}

					.importAreaFiligrane{
						margin-bottom: 12px;
					}

					.importButton{
						color: var(--text-inactive);
					}
				}
			}
		}
	}
	.csvPreview,
	.importCSVTable.rsTable,
	.modalCenter{
		overflow: hidden;
	}

	.importCSVTable{
		&.rsTable{
			.header,
			.tableRow{
				column-gap: 16px;
				display: grid;
				grid-template-columns: 20px 150px 170px 1fr;
			}

			.tableRows{
				border: 1px solid var(--border-grey-light);
				overflow-y: scroll;
			}

			.tableRow{
				&.checked{
					background: var(--white);
				}
			}

			.rsDropdown{
				width: 170px;
			}
		}
	}
}

.menuDropdownItems{
	
    flex-direction: column;
    padding: 0;

	&.show{
		display: flex;
		margin-top:10px;
	}

	.menuDropdownItem{
		align-items: center;
		cursor: pointer;
		display: flex;
		font-weight: 500;
		gap: 16px;
		padding: 8px 16px;

		&.disabled {
			background: var(--background-disable);
			cursor: none !important;
			filter: grayscale(100%);
			pointer-events: none;
		}

		&:first-child{
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding-top: 16px;
		}

		&:last-child{
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			padding-bottom: 16px;
		}

		.text{
			flex: 1;
			text-align: left;
		}
	}
}

.emptyPlaceholder{
	>div {
		text-align: center;

		img {
			margin-bottom: 16px;
		}
	}
}

.rsRightModal{
	background: var(--white);
	border: 1px solid var(--border-grey-light);
	bottom: 0;
	box-shadow: 0 0 4px rgba(0,0,0,.04), 0 8px 16px rgba(0,0,0,.08);
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 100%;
	overflow: hidden;
	position: fixed;
	right: 0;
	transform: translate3d(100%,0,0);
	transition: transform .4s cubic-bezier(.42,0,.27,.99);
	width: 534px;
	z-index: 1050;
	&.open{
		right: 0;
		transform: translateZ(0);
	}
	.rightLayoutContent{
		flex: 1;
		max-height: 100%;
		overflow-y: scroll;
		padding: 16px;
		&.leadCreateLayoutRight{
			padding:0;
			.titleContainer{
				padding: 16px;
			}
			> .content{
				overflow: scroll;
				padding: 16px;
			}

			.footer{
				border-top: 1px solid var(--border-grey-light);
				box-shadow: 0 -4px 20px rgba(0,0,0,.05);
				padding: 16px;

				button{
					width: 100%;
				}
			}
		}
		> .titleContainer{
			align-items: center;
			border-bottom: 1px solid var(--border-grey-light);
			display: flex;
			justify-content: space-between;
			padding: 0 0 16px;
		}
	}

	.closeLayoutRight{
		i{
			color: var(--text-standard);
		}
	}

	&.layoutRightEditReviewStep{
		border-radius: 10px;
		height: calc(100% - 32px);
		margin: 16px;
		width: 825px;
		right: -30px;
		.rightLayoutContent{
			overflow: auto;
			padding: 24px;
			.titleContainer{
				.rightLayoutContentLeft,
				.title{
					flex: 1;
				}
				.reviewTitle{
					.leadReviewStepPagination{
						flex-wrap: nowrap;
						width: auto;
					}
				}
			}
		}
	}
}

.leadSummary{
	display: flex;
	align-items: center;
	.leadPictureAndStale{
		position: relative;
		width: 55px;
	}
	&.sm{
		.leadPictureAndStale{
			width: 25px;
		}
		.leadContact{
			.leadName{
				font-size: 14px;
			}
		}
	}

	.leadContact{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		.leadHeader{
			display: flex;
			gap: 5px;
		}
		.leadName{
			align-items: center;
			display: inline-flex;
			font-size: 18px;
			font-weight: 600;
			gap: 8px;
			overflow: hidden;
			.leadEmailStatus{
				display: inline-flex;
			}
		}
		p{
			color: var(--text-light);
			font-weight: 500;
			margin: 0;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.leadPicture{
	align-items: center;
    background-position: 50%;
    background-size: cover;
    border-radius: 50%;
    color: var(--white);
    display: flex;
    font-size: 20px;
    font-weight: 600;
    height: 55px;
    justify-content: center;
    line-height: 55px;
    overflow: hidden;
    width: 55px;
	&.sm{
		font-size: 12px;
		height: 25px;
		line-height: 25px;
		width: 25px;
	}
}

.bottom{
	.dropdownTrigger{
		.btn{
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
	.campaignEditReviewLeftBottom{
		.reviewAllButton{
			flex: 1;
		}
	}
}

.rsTreeReview{
	display: flex;
	height: 100%;
	overflow: auto;
	padding: 16px;
	position: relative;
	flex: 1;
	.reviewOverlay{
		background: var(--black);
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		transition: opacity .3s ease-in-out;
		width: 100%;
		z-index: 101;
		&.show{
			opacity: .05;
			pointer-events: all;
		}
	}

	.reviewSteps{
		cursor: grab;
		flex: 1;
		transform-origin: top;
		transition: transform .3s;
	}

	.reviewStart{
		gap: 16px;
		justify-content: center;
		padding: 21px 16px;
		position: relative;
		width: 450px;		
	}

	.reviewStart,
	.reviewDelay{
		align-items: center;
		align-self: center;
		display: flex;
	}
	
	.rsTreeEditReviewStep,
	.reviewStart,
	.reviewDelay{
		background-color: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;
	}

	.reviewDelay{
		padding: 16px;
		width: 300px;
		gap: 10px;
		.text{
			font-weight: 500;
			.colored{
				color: var(--primary-color);
			}
		}
	}

	.reviewContent{
		padding-top: 0;
		word-break: break-word;		
	}

	.rsTreeEditReviewStep{
		width: 450px;
		&.rsLayoutRight{
			cursor: pointer;
		}

		.reviewTitle{
			padding: 16px;
			border: none;
			&:not(:only-child){
				border-bottom: 1px solid var(--border-grey-light);
			}
		}

		.reviewContent{
			max-height: 350px;
			overflow-y: auto;
			padding: 16px;

			*{
				white-space: normal;
				margin-bottom: 0;
			}
		}
	}

	.stepVerticalSeparatorLine{
		align-self: center;
		border-left: 1px solid var(--text-inactive);
		height: 30px;
		min-width: 0;
		width: 0;
	}

	.rsTreeReviewSequence{
		display: flex;
		align-items: center;

		.stepNumber{
			font-weight: 500;
		}
	}
}

.viewEmail{
	.messageSimulateEditor {
		color: var(--black);
	}
}

.personalizationModal{
	.modal-content{
		.modal-header{
			.modal-title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex: 1;

				.closeModal{
					border: 1px solid var(--border-grey-light);
					border-radius: 5px;
					color: var(--text-standard);
					cursor: pointer;
					padding: 5px;
					width: 28px;
				}
			}
		}
		.modal-body{
			.list{
				padding: 0 20px;
				.item{
					cursor: pointer;
					padding: 16px 0;
					&:not(:last-of-type){
						border-bottom: 1px solid var(--border-grey-light);
					}
					> div{
						display: flex;
						align-items: center;
						gap: 8px;
					}
					.colored{
						align-self: start;
						background: var(--primary-color-100);
						border-radius: 3px;
						color: var(--primary-color);
						font-weight: 600;
						padding: 2px 4px;
					}
				}
			}
		}
	}
}

.emptyLeads {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
	img{
		margin-bottom: 20px;
	}
	h1{
		font-weight: 600;
		margin-bottom: 4px;
		text-align: center;
	}
	.subtitle{
		color: var(--text-inactive);
		font-size: 16px;
		margin-bottom: 14px;
	}
}

.scheduleRow{
	align-items: center;
    border: 1px solid var(--border-grey-light);
    border-radius: 10px;
    display: flex;
    padding: 16px;
	&.campaign{
		background: var(--background-grey-light);
	}
	.block{
		border-left: 1px solid var(--border-grey-light);
		display: flex;
		flex-direction: column;
		padding-left: 8px;
		padding-right: 8px;
		&:first-child{
			border-left: 0;
		}
		&.name{
			width: 200px;
			.value{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		&.days{
			width: 75px;
			i{
				font-size: 12px;
    			margin-left: 8px;
			}
		}
		&.send,
		&.start,
		&.end{
			width: 85px;
		}

		&.timezone{
			width: 115px;
			.value{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.title{
			color: var(--text-light);
    		font-size: 12px;
		}

		.value{
			font-weight: 500;
		}
	}

	.dropdownTrigger{
		margin-left: 40px;
	}
}

.stats {
	border: 1px solid var(--border-grey-light);
	border-radius: 10px;
	display: flex;
	flex: 0 0 auto;
	margin-bottom: 20px;
	padding: 0;
	position: relative;
}

.statsDetailed {
	align-items: stretch;
	background: var(--white);
	display: flex;
	flex: 1;
	flex-flow: row nowrap;
	justify-content: stretch;
	user-select: none;

	.statColumn{
		align-items: flex-start;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		overflow: auto;
		&:first-child{
			flex: 0 0 80px;
			position: relative;

			.statLine{
				grid-template-columns: auto;
    			place-items: center;
			}
		}

		&:last-child{
			.statLine{
				overflow: hidden;
			}
		}
	}

	&.positive{
		.statLine{
			grid-template-columns: repeat(auto-fit,minmax(115px,1fr));
		}
	}

	.statLine{
		align-content: center;
		display: grid;
		flex: 0 0 60px;
		grid-auto-flow: column;
		min-width: 100%;
		z-index: 0;
	}

	.statHead{
		flex: 0 0 80px;
	}

	.statCell{
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		justify-content: stretch;
		min-width: 180px;
		position: relative;
	}
}

.statMetrics {
    display: block;
    flex: 0 0 auto;
    list-style: none;
    margin: 0;
    padding: 0;
    width: calc(100% - 24px);
    z-index: 0;
	 &:has(.nullPlaceholder){
		display: flex;
		justify-content: center;
	 }
	.metric{
		align-items: center;
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		transition: all .234s ease-in-out;
		> div,
		> span{
			position: relative;
		}
		.statMetricsPercent{
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
			flex: 0 0 auto;
			font-size: 14px;
			font-weight: 600;
			min-width: 50px;
			padding: 4px 8px;
			text-align: center;
			z-index: -1;
		}

		.statMetricsValue{
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			display: inline-block;
			flex: 1 1 auto;
			font-size: 14px;
			font-weight: 500;
			overflow: hidden;
			padding: 4px 8px;
			text-align: center;
			text-overflow: ellipsis;
			white-space: nowrap;
			z-index: -1;

			img{
				height: 21px;
			}
		}
	}
}

.activities {
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	height: 100%;
	position: relative;

	.emptyActivities{
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 100%;
		justify-content: center;
	}

	.emptyPlaceholder{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.d-flex-col{
		&.content{
			gap: 0;
			padding: 0;
			.activity{
				display: flex;
				position: relative;
			}
		}
	}

	.uiSection{
		> .content{
			overflow: auto;
		}
	}

	.d-flex-row{
		&.activity{			
			padding: 16px;
			&:not(:last-of-type){
				border-bottom: 1px solid var(--border-grey-light);
			}
			&.compact{
				padding: 0 0 16px;
				.activityContainer{
					gap: 0;
				}
			}
			.activityTypeIcon{
				align-items: center;
				border-radius: 10px;
				display: flex;
				height: 40px;
				justify-content: center;
				padding: 7px;
				width: 40px;
				&.colorOpened{
					background-color: var(--reports-opened-color-100);
				}
				&.colorSent{
					background-color: var(--reports-sent-color-100);
				}
				&.colorLinkClicked{
					background-color: var(--reports-clicked-color-100);
				}
				&.colorReplied{
					background-color: var(--reports-replied-color-100);
				}
				&.colorInterested{
					background-color: var(--reports-interested-color-100);
				}
			}
			.activityContainer{
				display: flex;
				flex: 1;
				gap: 16px;
				.activityCenter{
					display: block;
					flex: 1;
					overflow: hidden;
					width: 0;
					.activityLeadName{
						font-weight: 600;
						cursor: pointer;
						span{
							display: block;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					.activityStep{
						color: var(--primary-color);
    					font-weight: 500;
					}
					.activityMessage{
						color: var(--text-light);
						font-weight: 500;
					}
					.rsShowActivityMessage{
						span{
							text-decoration: underline;
							text-underline-offset: 2px;
						}
					}
				}
			}
		}
	}
}

.d-flex-col{
	&.reports{
		.d-flex-row{
			&.campaignDetails{
				>.activities{
					flex: 5;
    				overflow: hidden;
					>.uiSection{
						>.content{
							>.activities{
								max-height: calc(100vh - 190px);
							}
						}
					}
				}
				> .sequences{
					flex: 13;
    				overflow: hidden;
				}
			}
		}
		.uiSection{
			&.mvp{
				flex: 1;
			}
		}
	}
}

.tableReports{
	background: var(--white);
	border: 1px solid var(--border-grey-light);
	border-radius: 10px;
	display: flex;
	flex: 0 0 auto;
	flex-flow: row nowrap;
	margin: 0;
	padding: 0;
	position: relative;
	user-select: none;
	&.tableSmall{
		.tableColumn{
			&.tableHeadY{
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				flex: 0 0 210px;
				max-width: 210px;
				.tableLine{
					align-items: center;
					background: var(--white);
					padding: 0 20px 0 8px;
					width: 100%;
					&:not(.tableHeadX){
						border-bottom-left-radius: 8px;
					}
				}
			}

		}

		.tableLine{
			height: 60px;
			min-width: 100%;
			&.tableHeadX{
				border-top-left-radius: 10px;
				height: 94px;
				.tableStats{
					height: 94px;
				}
			}

			.tableStat{
				min-width: 85px;
			}
			&:not(.tableHeadX){
				.tableStat{
					height: 60px;
				}
			}
			.statMetrics{
				width: unset;
			}
		}		
	}
	.tableColumn{
		align-content: flex-start;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
		display: flex;
		flex: 1 1 auto;
		flex-flow: row wrap;
		overflow: auto;
		position: relative;
		&.tableHeadY{
			border-right: 1px solid var(--border-grey-light);
			display: block;
			flex: 0 0 210px;
			max-width: 250px;
			overflow: visible;
			position: relative;
			.tableLine{
				.stepType{
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-width: 52px;
					.stepName{
						font-size: 12px;
						font-weight: 500;
					}
				}
			}
		}
	}

	.tableLine{
		align-items: stretch;
		background: var(--white);
		display: flex;
		flex: 1 0 auto;
		flex-flow: row nowrap;
		height: 80px;
		justify-content: flex-start;
		min-width: 100%;
		position: relative;

		&+ .tableLine{
			box-shadow: inset 0 1px 0 rgba(8,39,167,.13);
		}

		&.tableHeadX{
			height: 133px;
			position: relative;
			width: 100%;

			.tableStats{
				background: var(--white);
				height: 105px;
				padding-top: 45px;

				&:last-of-type{
					&::before{
						border-bottom: 1px solid var(--border-grey-light);
						border-right: 0;
						border-top-right-radius: 10px;
					}
				}

				&::before{
					background: var(--background-grey-light);
					border-bottom: 1px solid var(--border-grey-light);
					border-right: 1px solid var(--border-grey-light);
					content: "";
					height: 45px;
					left: 1px;
					position: absolute;
					right: -1px;
					top: 0;
					z-index: 0;
				}
				.tableStat{
					box-shadow: unset;
					height: 49px;
				}
			}

			.tableStat{
				overflow: visible;
				position: relative;
				.statName{
					border-radius: 5px;
					display: grid;
					height: 32px;
					place-items: center;
					width: 60px;
				}
			}
		}
	}

	.tableHeadTitle{
		margin: auto;
	}

	.tableContentInfos{
		flex: 1 1 0;
		padding: 0 10px;
		width: 0;
		.tableContentTitle{
			color: var(--primary-color);
			font-size: 14px;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			small{
				color: var(--text-inactive);
				font-size: 14px;
			}
		}
		.tableContentSubtitle{
			color: var(--text-light);
			font-size: 12px;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.tableContentToSend{
		background: var(--primary-color-100);
		border-radius: 4px 0 0 4px;
		color: var(--primary-color);
		font-size: 14px;
		font-weight: 600;
		margin-right: -20px;
		padding: 4px 8px;
	}

	.tableStats{
		display: flex;
		flex: 1 0 auto;
		flex-flow: row nowrap;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
	}

	.tableStat{
		align-content: center;
		align-items: center;
		display: flex;
		flex: 1 0 85px;
		flex-flow: row wrap;
		font-size: 12px;
		font-weight: 800;
		height: 40px;
		justify-content: center;
		min-width: 85px;
		overflow: hidden;
		position: relative;
		.tableStatValue{
			align-content: center;
			align-items: center;
			display: flex;
			flex-flow: row wrap;
			inset: 0;
			justify-content: center;
			position: absolute;
			width: 100%;
		}
		.tableStatValue,
		.tableStatValue *{
			pointer-events: none;
		}

		.tableStatLabel,
		.tableStatNumber{
			border-radius: 4px;
			display: inline-block;
			flex: 0 0 auto;
			max-width: 100%;
			overflow: hidden;
			padding: 4px 8px;
			text-align: center;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.statHead{
		left: calc(50% - 16px);
		position: absolute;
		right: 0;
		top: 8px;
	}
}

.nullPlaceholder{
	background: var(--background-grey-medium);
	border-radius: 3px;
	height: 6px;
	width: 25px;
}

.unsubscribes{
	.unsubscribesForm{
		.textareaScrollController{
			background: var(--white);
			border: 1px solid var(--border-grey-light);
			border-radius: 8px;
			margin-bottom: 16px;
			max-height: 322px;
			overflow-y: scroll;
			.textareaContainer{
				align-items: stretch;
				display: flex;
				overflow: hidden;
				.textareaLineCount{
					align-items: center;
					background-color: var(--background-grey-light);
					color: var(--text-inactive);
					display: flex;
					flex-direction: column;
					line-height: 20px;
					padding: 10px 0;
					text-align: right;
					width: 56px;					
				}
				.textEdit{
					flex-grow: 1;
					.inputWrapper{
						border: none;
						height: 100%;
						textarea{
							height: 100%;
							resize: none;
						}
					}
				}
			}
		}
	}
	.unsubscribeList{
		.rsTable{
			.header,
			.tableRow{
				column-gap: 20px;
				display: grid;
				grid-template-columns: 50px 1fr 1fr 160px;
			}
		}
	}
}

.teamPageWrapper{

	.rsTabsWrapper{
		color: var(--text-light);
		display: inline-flex;
		margin-bottom: 16px;
	
		.rsTab{
			border-bottom: 1px solid var(--border-grey-light);
			color: var(--text-light);
			flex-grow: 1;
			padding: 10px 20px;
			text-align: center;
			cursor: pointer;
			&.active{
				border-bottom: 3px solid var(--primary-color);
				color: var(--text-standard);
				font-weight: 700;
			}
		}
	}

	.noTeams{
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 32px;
		justify-content: center;
		margin-top: 100px;
		.rsInfoMsg{
			width: fit-content;
		}
	}
}

.licenseSummary {
    border: 1px solid var(--border-grey-light);
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0,0,0,.04), 0 4px 8px rgba(0,0,0,.06);
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
	.seatsUsed{
		color: var(--primary-color-400);
	}
	.gaugeContainer{
		background: var(--background-grey-light);
		border-radius: 100px;
		height: 8px;
		.gaugeBar{
			border-bottom-left-radius: 100px;
			border-top-left-radius: 100px;
			height: 8px;
			background: var(--primary-color);
		}
	}
}

.integrationBlock {
    align-items: center;
    background: var(--white);
    border: 1px solid var(--border-grey-light);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

	.left{
		align-items: center;
		display: flex;
		gap: 8px;
	}

	.right{
		align-items: center;
		display: flex;
		gap: 16px;
	}

	.header{
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.title{
		font-size: 16px;
		font-weight: 600;
	}

	.subtitle{
		color: var(--text-light);
	}
}

.leadLayoutRightNext{
	flex: 1;
	min-height: 0;
}

.leadCampaignProgression{
	background: var(--background-grey-medium);
    border: 1px solid var(--border-grey-light);
    border-radius: 10px;
    padding: 8px 8px 8px 16px;
	h4{
		overflow: hidden;
		text-decoration: underline;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0;
	}
	.leadState{
		align-items: center;
		background: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;
		display: flex;
		flex: 0 0 auto;
		gap: 8px;
		padding: 8px 12px;
		i{
			&.success{
				color: var(--success);
			}
			&.error{
				color: var(--alert);
			}
		}
	}
	.campaignTaskContainer{
		align-items: center;
		display: flex;
		gap: 8px;
		justify-content: space-around;
		margin: 0;
		// overflow: hidden;
		padding: 10px 0;

		.task{
			align-items: center;
			background: var(--border-grey-light);
			border: 2px solid var(--white);
			border-radius: 10px;
			color: var(--text-inactive);
			display: flex !important;
			flex: 0 0 auto;
			height: 35px;
			justify-content: center;
			margin: 0;
			min-width: 35px;
			position: relative;

			&.success{
				background: var(--success-100);
    			color: var(--success);
			}
			
			i{
				font-size: 16px;
			}

			&.currentTask{
				animation: pulse-outline .8s ease-in-out infinite alternate both;
				background: #213856;
				border: unset;
				color: var(--white);
				flex: 1 0 auto;
				gap: 8px;
				outline: 5px solid #e2e9ef;
				padding: 8px 16px;
				&:not(.error){
					margin: 0 8px;
				}
			}

			&.wrapperRight{
				border: 2px solid var(--white);
				padding: 8px 8px 8px 16px;
				z-index: 0;
			}
		}

		.taskWrapper{
			align-items: center;
			display: flex !important;
			gap: 8px;
		}

		.separator{
			color: var(--text-standard);
		}

		.doneTasks,
		.futureTasks{
			display: flex;
			margin: 0;
			padding: 0;
			z-index: 1;
		}

		.doneTasks{
			display: grid;
			grid-template-columns: repeat(auto-fit,minmax(10px,max-content));
			max-width: 157px;
		}

		.futureTasks{
			max-width: 73px;
			&:has(+.task.wrapperRight){
				margin-right: -20px;
			}
		}
	}
}

@keyframes pulse-outline {
    0% {
        outline-color: rgba(33,56,86,.15);
        outline-width: 5px
    }

    to {
        outline-color: rgba(33,56,86,.3);
        outline-width: 8px
    }
}

.leadLayoutRightNext{
	.leadTabs{
		border-bottom: 1px solid var(--border-grey-light);
		display: grid;
		grid-template-columns: repeat(3,1fr);
		.leadTab{
			cursor: pointer;
			padding: 10px 0;
			text-align: center;
			&.selected{
				border-bottom: 2px solid var(--primary-color);
			}
		}
	}

	.leadTabContent{
		height: 100%;
		min-height: 0;
		overflow-y: auto;
		.note{
			padding: 24px 16px;
			&+ .note{
				border-top: 1px solid var(--border-grey-light);
			}
		}
	}

	.leadFormSection{
		overflow: scroll;
		.leadFormSectionContent{
			padding: 16px 8px 1px;
			&.leadGeneralInfo{
				.rsLeadFormField{
					min-width: 45%;
				}
			}
		}
	}

	.leadFormSectionHeader{
		align-items: center;
		background: var(--background-grey-medium);
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
	}
}

.rsLeadFormField{
	border-radius: 10px;
   padding: 8px;
	&.focused,
	&:not(.readOnly):hover{
		box-shadow: 0 0 0 1px var(--border-grey-light);
		cursor: pointer;
	}
	label{
		color: var(--text-standard);
		font-size: 14px;
		pointer-events: none;
	}
	.fieldValue{
		margin: 5px 0 4px;
		overflow: hidden;
		padding: 0 4px;
		text-overflow: ellipsis;
		&.filled{
			color: var(--text-light);
		}
	}
}

.tippy-tooltip-content{
	.tooltipTaskLine{
		align-items: center;
		display: flex;
		gap: 8px;
		padding: 8px 0;
		font-size: 14px;
		line-height: 1.4;
		&+ .tooltipTaskLine{
			border-top: 1px solid hsla(0,0%,100%,.1);
		}
		.stepIndex{
			flex: 0 0 45px;
			font-weight: 500;
		}

		.stepDesc{
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			gap: 4px;
			text-align: left;
			.stepTitle{
				font-weight: 600;
			}
			.stepSentAt{
				font-weight: 500;
			}
			.stepStatus{
				font-size: 12px;
    			font-weight: 600;
				display: flex;
				align-items: center;
				i{
					margin-right: 8px;
				}
				&.success{
					color: var(--tertiary1-color-100);
				}
				&.current{
					color: var(--warning);
				}

				&.future{
					color: var(--primary-color-200);
				}
			}
		}
	}
}

.tippy-popper{
	.tippy-tooltip{
		padding: 0;
		.tippy-popper[x-placement^=bottom] [x-arrow]{
			border-bottom: 7px solid var(--tooltip-background) !important;
		}
	}
}