.contentHeadWrapper {
	background: var(--primary-color-500);
	color: #fff;
	border-bottom: 1px solid var(--border-grey-light);
	height: 74px;
	justify-content: space-between;
	padding: 16px;
	position: relative;
	user-select: none;
	align-items: center;
	display: flex;

	.contentHeadRightWrapper {
		align-items: center;
		display: flex;
		gap: 16px;
		justify-content: flex-end;
	}
}

.logoHeaderWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;
	position: relative;
	gap: 25px;

	svg {
		transform: rotate(180deg);
	}

	&.repeatIcon {
		svg {
			transform: rotate(0deg);
		}
	}
}