.ql-snow {
	.ql-picker {
		&.ql-size {

			.ql-picker-label{
				&:not([data-value=""]){
					&::before{
						content: "14px" !important;
					}
				}
			}
			.ql-picker-item{
				&::before {
					content: attr(data-value) !important;
					font-size: attr(data-value) !important;
				}
			}
		}
	}
}

.ql-editor .ql-font-arial {
	font-family: Arial, Helvetica, sans-serif;
}

.ql-editor .ql-font-georgia {
	font-family: Georgia,serif;
}

.ql-editor .ql-font-impact {
	font-family: Impact,Charcoal,sans-serif;
}

.ql-editor .ql-font-tahoma {
	font-family: Tahoma,Geneva,sans-serif;
}

.ql-editor .ql-font-times-new-roman {
	font-family: Times New Roman,Times,serif,-webkit-standard;
}

.ql-editor .ql-font-verdana {
	font-family: Verdana,Geneva,sans-serif;
}

.ql-editor .ql-font-lucida-console {
	font-family: Lucida Console,Courier New,serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before{
	content: "Arial";
	font-family: Arial, Helvetica, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before{
	content: "Georgia";
	font-family: Georgia,serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="impact"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="impact"]::before{
	content: "Impact";
	font-family: Impact,Charcoal,sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before{
	content: "Tahoma";
	font-family: Tahoma,Geneva,sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before{
	content: "Times New Roman";
	font-family: Times New Roman,Times,serif,-webkit-standard;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before{
	content: "Verdana";
	font-family: Verdana,Geneva,sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida-console"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida-console"]::before{
	content: "Lucida Console";
	font-family: Lucida Console,Courier New,serif;
}
.signatureTextEditor{

	.ql-snow{
		.moreParagraphOption{
			
			display: block;			
			height: 0;
			overflow: hidden;
			&.expanded{
				box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.05);
				padding-left: 8px !important;
				background: var(--white);
				margin-left: -16px;
				margin-top: -56px;
				transition: none;
				margin-right: -16px;
				height: 48px;
				overflow: visible;
			}
			button{
				svg{
					width: 18px;
				}
			}

			.ql-picker.ql-font .ql-picker-label::before {
				content: "\f031" !important;
				font-family: "Font Awesome 6 Free" !important;
				font-weight: 900 !important;
				font-size: 16px !important;
			}

			span.ql-size.ql-picker,
			span.ql-font.ql-picker {
				width: 50px !important;
			}

			.ql-picker.ql-size .ql-picker-label::before {
				font-family: "Font Awesome 6 Free";
				font-weight: 900;
				content: "\f034" !important;
			}
		}
		.ql-snow .ql-picker.ql-expanded .ql-picker-options {
			height: 220px;
			overflow: auto;
			top: -220px;
		}
		.ql-picker.ql-expanded .ql-picker-label{
			background: var(--primary-color-100);
			color: var(--primary-color);
			border: none;
			.ql-stroke{
				stroke: var(--primary-color);
			}
		}
	}
}

.ql-snow .ql-color-picker .ql-picker-label svg{
	display: none;
}

.ql-snow .ql-color-picker .ql-picker-label::before{
	content: "\f53f" !important;
	font-family: "Font Awesome 6 Free" !important;
	font-weight: 900 !important;
	font-size: 16px !important;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item{
	display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1;
}

.ql-snow .ql-color-picker.ql-color.ql-expanded .ql-picker-options{
	width: 264px;
	padding: 20px;
}