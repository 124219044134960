body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--white: #fff;
	--black: #000;
	--always-white: #fff;
	--always-grey: #404040;
	--always-grey-light: #ddd;
	// --tertiary1-color-100: #eafff6 !important;
	// --tertiary1-color-200: #b4ffe2 !important;
	// --tertiary1-color: #91e0bf !important;
	// --tertiary1-color-400: #44c38e !important;
	// --tertiary1-color-500: #0d5034 !important;
	// --tertiary1-color-a15: rgba(145, 224, 191, .15) !important;
	// --tertiary2-color-100: #fff0ec !important;
	// --tertiary2-color-200: #ffdabf !important;
	// --tertiary2-color: #f5967e !important;
	// --tertiary2-color-400: #de5837 !important;
	// --tertiary2-color-500: #892f19 !important;
	// --border-disabled: #b7bec6 !important;
	// --border-grey-light: #efefef !important;
	// --border-inactive: #dedede !important;
	// --border-active: #838990 !important;
	// --background-grey-light: #fbfbfb !important;
	// --background-grey-medium: #f6f6f6 !important;
	// --background-grey-strong: #ececec !important;
	// --background-disable: #f3f6f9 !important;
	--tertiary1-color-100: #ecd8ff !important;
	--tertiary1-color-200: #ba99ff !important;
	--tertiary1-color: #8a3bff !important;
	--tertiary1-color-400: #6600cc !important;
	--tertiary1-color-500: #4d0099 !important;
	--tertiary1-color-a15: rgba(138, 61, 255, .15) !important;
	--tertiary2-color-100: #ffedf6 !important;
	--tertiary2-color-200: #ffbae2 !important;
	--tertiary2-color: #ff8ae0 !important;
	--tertiary2-color-400: #cc66cc !important;
	--tertiary2-color-500: #990099 !important;
	--border-disabled: #b7bec6 !important;
	--border-grey-light: #dedede !important;
	--border-inactive: #dedede !important;
	--border-active: #838990 !important;
	--background-grey-light: #fbfbfb !important;
	--background-grey-medium: #f6f6f6 !important;
	--background-grey-strong: #ececec !important;
	--background-disable: #f3f6f9 !important;
	--background-paper: url(../images/grain-texture-light.png) !important;
	--background-dotted: url(../images/bg-dotted.png) !important;
	--background-overlay: rgba(0, 0, 0, .05) !important;
	--tooltip-background: var(--text-standard);
	--tooltip-text-inactive: var(--text-inactive);
	--loading-mask: hsla(0, 0%, 100%, .9) !important;
	--loading-background: linear-gradient(270deg, hsla(0, 0%, 65%, .05) 0%, #e6e6e6 96.87%, hsla(0, 0%, 74%, 0) 100%), var(--white);
	// --information-100: #e9f3ff;
	// --information: #316bff;
	// --information-400: #134cdd;
	// --success-100: #e5fce5;
	// --success-200: #9ae299;
	// --success: #31c52e;
	// --success-400: #009e00;
	// --success-500: #007300;
	// --warning-100: #fff8d3;
	// --warning-200: #ffe172;
	// --warning: #dfb009;
	// --warning-400: #d9aa00;
	// --warning-500: #916d00;
	// --alert-100: #ffe7e2;
	// --alert-200: #f8b1b1;
	// --alert: #ec4343;
	// --alert-400: #be0723;
	// --alert-500: #8c0000;
	--information-100: #e9d8ff;
	--information: #8a3bff;
	--information-400: #6600cc;
	--success-100: #e5d8e5;
	--success-200: #ba99ba;
	--success: #8a3b8a;
	--success-400: #660066;
	--success-500: #4d004d;
	--warning-100: #fff8d3;
	--warning-200: #ffe172;
	--warning: #dfb009;
	--warning-400: #d9aa00;
	--warning-500: #916d00;
	--alert-100: #ffe7e2;
	--alert-200: #f8b1b1;
	--alert: #ec4343;
	--alert-400: #be0723;
	--alert-500: #8c0000;
	--reports-sent-color-100: #edf3ff;
	--reports-sent-color-300: #94b5ff;
	--reports-sent-color-400: #263c6c;
	--reports-opened-color-100: #fff7ef;
	--reports-opened-color-300: #ffb992;
	--reports-opened-color-400: #5f4323;
	--reports-clicked-color-100: #f5f0ff;
	--reports-clicked-color-300: #d3bcfc;
	--reports-clicked-color-400: #2d1a51;
	--reports-replied-color-100: #ebecfd;
	--reports-replied-color-300: #b1b4ff;
	--reports-replied-color-400: #26297a;
	--reports-interested-color-100: #fff2f2;
	--reports-interested-color-300: #f9a2a2;
	--reports-interested-color-400: #5a3838;
	--reports-bounced-color-100: #fbfbfb;
	--reports-bounced-color-300: #eaeaea;
	--reports-bounced-color-400: #5e5e5e;
	--reports-unsubscribed-color-100: #fffaf3;
	--reports-unsubscribed-color-300: #f8d9b0;
	--reports-unsubscribed-color-400: #7e6440;
	--reports-skipped-color-100: #fff8f8;
	--reports-skipped-color-300: #f7cccb;
	--reports-skipped-color-400: #5f3e3d;
	--reports-notinterested-color-100: #fff2f2;
	--reports-notinterested-color-300: #ff7982;
	--reports-notinterested-color-400: #700f16;
	--reports-task-color-100: #fff8d3;
	--reports-call-color-100: #ffedff;
	--reports-pause-color-100: #f6f6f6;
	--reports-meeting-booked-color-100: #fdf1e7;
	--reports-meeting-booked-color-300: #ffb866;
	--reports-meeting-booked-color-400: #8b4900;
	--text-inactive: #98a1ac;
	--text-standard: #213856;
	--text-light: #566f8f;
	// --primary-color-100: #e9f3ff !important;
	// --primary-color-200: #bbceff !important;
	// --primary-color: #316bff !important;
	// --primary-color-400: #134cdd !important;
	// --primary-color-500: #0132b0 !important;
	--primary-color-100: #e9d8ff !important;
	--primary-color-200: #bb99ff !important;
	--primary-color: #8a3bff !important;
	--primary-color-400: #6600cc !important;
	--primary-color-500: #4d0099 !important;
	--primary-color-a15: rgba(49, 107, 255, .15) !important;
	--secondary-color-100: #ffeef2 !important;
	--secondary-color-200: #ffb0cc !important;
	--secondary-color: #ec6181 !important;
	--secondary-color-400: #d63258 !important;
	--secondary-color-500: #641225 !important;
	--text-inactive: #98a1ac !important;
	--text-standard: #213856 !important;
	--text-light: #566f8f !important;
	// --btn-border-color: #0e51ff !important;
	// --btn-outline-color: #5988ff !important;
	// --btn-shadow-color: rgba(49, 107, 255, .28) !important;
	--btn-border-color: #8a3bff !important;
	--btn-outline-color: #4d0099 !important;
	--btn-shadow-color: rgba(138, 61, 255, .28) !important;
	background-color: var(--white);
	color: var(--text-standard);
	font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
	font-size: 14px !important;
}

.bold {
	font-weight: 600;
}

h1,
h2,
h3,
h4,
h5 {
	margin-bottom: 0;
}

h2 {
	font-size: 20px;
}

h1 {
	font-size: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	word-break: break-word;
}

h4 {
	font-size: 16px;
}

hr {
	border-top: 1px solid var(--border-grey-light);
	margin: 0;
	width: 100%;
}

.maxWidth200{
	max-width: 200px;
}

.highlightCustomVariable,
.highlightLiquidSyntax {
	border-radius: 3px;
	color: var(--text-standard);
	padding: 2px 4px;
	width: fit-content;
}

.highlightCustomVariable {
	background: var(--primary-color-200);
}

.secondary-color {
	color: var(--secondary-color);
}

.input-group{
	.textEdit{
		&:first-child{
			.rsTextEditBorder{
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}

.roundSales {
	.btn {
		align-items: center;
		border: 0;
		border-radius: 10px;
		display: inline-flex;
		flex: 0 0 auto;
		font-size: 14px;
		font-weight: 500;
		gap: 10px;
		justify-content: center;
		overflow: hidden;
		padding: 8px 20px;
		position: relative;
		text-decoration: none;
		text-overflow: ellipsis;
		vertical-align: unset;
		white-space: nowrap;
		cursor: pointer;
		user-select: none;

		&.btn-secondary {
			-webkit-text-fill-color: unset;
			background: var(--white);
			color: var(--primary-color);
			box-shadow: inset 0 0 0 1px var(--border-grey-light);
			height: 45px;
			&.btn-ico{
				height: 45px;
				width: 45px;
				&.sm{
					border-radius: 8px;
					height: 32px;
					padding: 8px;
					width: 32px;
				}
			}

			&.btn-danger{
				background-color: var(--white);
				border: unset;
				box-shadow: inset 0 0 0 1px var(--alert) !important;
				color: var(--alert)!important;
			}
			&.disabled{
				background: var(--background-disable);
				border: 1px solid var(--border-grey-light);
				box-shadow: none;
				color: var(--text-inactive);
				outline: unset;
			}

			&.btn-date-picker{
				color: var(--text-standard);
			}

			&.btnBack{
				background: var(--white);
				border: 1px solid var(--border-grey-light);
				border-radius: 5px;
				color: var(--text-standard);
				cursor: pointer;
				height: 32px;
				padding: 8px;
				width: 32px;
				&::after{
					content: "\f053";
					font-family: "Font Awesome 6 Free";
					font-weight: 900;
				}
			}
		}

		&.btn-primary {
			background: radial-gradient(163.33% 163.33% at 50% 100%, hsla(0, 0%, 100%, .45) 0, transparent 100%, hsla(0, 0%, 100%, 0) 100%), var(--primary-color);
			background-blend-mode: overlay, normal;
			border: 1px solid var(--btn-border-color);
			box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
			color: var(--always-white);
			height: 50px;

			&.disabled{
				background: var(--background-disable);
				border: 1px solid var(--border-grey-light);
				box-shadow: none;
				color: var(--text-inactive);
				outline: unset;
			}

			&.btn-success{
				&:not(.disabled){
					&:not(:disabled){
						background: radial-gradient(163.33% 163.33% at 50% 100%,hsla(0,0%,100%,.45) 0,transparent 100%,hsla(0,0%,100%,0) 100%),var(--success);
						background-blend-mode: overlay,normal;
						border: 1px solid var(--success-400);
						box-shadow: inset 0 0 0 1px var(--success-200),0 3px 4px rgba(49,197,46,.28)!important;
						color: var(--white);
					}
				}
			}

			&.btn-warning{
				&:not(.disabled){
					&:not(:disabled){
						background: radial-gradient(163.33% 163.33% at 50% 100%,hsla(0,0%,100%,.45) 0,transparent 100%,hsla(0,0%,100%,0) 100%),var(--warning);
						background-blend-mode: overlay,normal;
						border: 1px solid var(--warning-400);
						box-shadow: inset 0 0 0 1px var(--warning-200),0 3px 4px rgba(223,176,9,.28)!important;
						color: var(--white);
					}
				}
			}

			&.btn-danger{
				&:not(.disabled){
					&:not(:disabled){
						background: radial-gradient(163.33% 163.33% at 50% 100%,hsla(0,0%,100%,.45) 0,transparent 100%,hsla(0,0%,100%,0) 100%),var(--alert);
						background-blend-mode: overlay,normal;
						border: 1px solid var(--alert-400);
						box-shadow: inset 0 0 0 1px var(--alert-200),0 3px 4px rgba(236,67,67,.28)!important;
						color: var(--white);
					}
				}
			}
		}

		&.textCta {
			background-color: transparent !important;

			&.btn-outline-danger {
				border: 0;
				box-shadow: unset;
				color: var(--alert) !important;
				outline: 0;
				&:disabled{
					color: var(--text-inactive)!important;
				}
			}
		}

		&.btn-stepper {
			-webkit-text-fill-color: unset;
			color: var(--text-standard);
			&.btn-ico{
				height: 40px;
				width: 40px;
			}
			&.disabled,
			&:disabled {
				color: var(--text-inactive);
			}
		}

		&.btnAction {
			background: transparent;
			border-radius: 5px;
			color: var(--text-standard);
			cursor: pointer;
			height: 32px;
			padding: 8px;
			width: 32px;

			&.disabled,
			&:disabled {
				color: var(--text-inactive);
			}

			&.btn-secondary{
				box-shadow: none;
			}
		}

		&:disabled{
			cursor: not-allowed!important;
			pointer-events: auto;
		}

		&.md {
			height: 45px;
		}

		&.btn-tertiary {
			border-radius: 0;
			color: var(--text-standard);
			padding: 0;
			box-shadow: none;
			height: auto;
			&:not(.btn-ico):has(i) > :not(i),
			&:not(.btn-ico):not(:has(i)) {
				text-decoration: underline;
				text-underline-offset: 2px;
			}

			&.btn-warning{
				background-color: transparent;
				border: 0;
				box-shadow: unset;
				color: var(--warning)!important;
				outline: 0;
			}
		}
	}

	.btnModalClose {
		background: var(--white) !important;
		border: 1px solid var(--border-grey-light) !important;
		border-radius: 5px !important;
		color: var(--text-standard) !important;
		cursor: pointer !important;
		height: 32px !important;
		padding: 8px !important;
		width: 32px !important;
		box-shadow: none !important;
	}

	.paperBackground {
		background-image: var(--background-paper) !important;
		background-repeat: repeat;
	}
}

h3 {
	font-size: 18px;
	line-height: 1.2;
}

.bold {
	font-weight: 600;
}

.text-light {
	color: var(--text-light) !important;
}

.d-flex-row {
	display: flex;
	flex-flow: row nowrap;
	gap: 16px;

	&.lg {
		gap: 32px;
	}

	&.sm {
		gap: 8px;
	}

	&.xs {
		gap: 4px;
	}

	&.noGap {
		gap: 0;
	}

	&.mvpBox {
		background: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;
		box-shadow: 0 0 6px rgba(0, 0, 0, .02), 0 2px 4px rgba(0, 0, 0, .08);
		padding: 16px 32px;

		&.margins {
			margin: 16px 16px 0;
		}
	}
}

.d-flex-col {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&.lg {
		gap: 32px;
	}

	&.sm {
		gap: 8px;
	}

	&.xs {
		gap: 4px;
	}

	&.noGap {
		gap: 0;
	}
}

.flex-1,
.spacer {
	flex: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #000 !important;
	color: #000 !important;
}

input:-internal-autofill-selected {
	background-color: #fff !important
}

.d-flex-col h1,
.d-flex-col h2,
.d-flex-col h3,
.ud-flex-col h4 {
	margin: 0;
}

.error_field {
	.errorIcon {
		position: absolute;
		color: red;
		right: 10px;
		top: calc(50% - 7px);
	}

	.input-feedback {
		position: absolute;
		color: red;
		font-size: 12px;
		top: -16px;
		right: 0;
	}

	.form-control {
		border-color: red;
	}

	&.passwordField {
		.input-group-text {
			border-color: red;
		}

		.errorIcon {
			right: 40px;
		}
	}
}

.small {
	font-size: 12px;
}

.text-inactive {
	color: var(--text-inactive);
}

a,
a:hover {
	color: unset;
	text-decoration: none;
}

html,
body,
#root {
	display: flex;
	flex-direction: column;
	height: 100% !important;
}

.noVisibleScrollbar::-webkit-scrollbar {
	height: 0;
	width: 0
}

::-webkit-scrollbar {
	height: 6px;
	width: 6px
}

::-webkit-scrollbar-track {
	background: #f1f1f100;
	margin: 4px
}

::-webkit-scrollbar-thumb {
	background: var(--border-grey-light);
	border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
	background: var(--border-disabled)
}

::-webkit-scrollbar-corner {
	background: transparent
}

.avatarSecondary0 {
	background: var(--primary-color-100);

	i {
		color: var(--primary-color);
	}
}

.avatar {
	color: var(--white);
	font-weight: 600;
	position: relative;
	&:has(img.picture){
		background-color: unset;
	}
}

.avatar,
.avatar .picture,
.avatar i {
	align-items: center;
	background: var(--white);
	display: flex;
	font-size: 19px;
	height: 35px;
	justify-content: center;
	object-fit: cover;
	user-select: none;
	width: 35px;
	word-break: normal;
}

.avatar.lg,
.avatar.lg .picture,
.avatar.lg i {
	font-size: 25px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}

.avatar.uiTeamPicture .picture,
.avatar.uiTeamPicture i {
	border: 1px solid var(--border-grey-light);
	border-radius: 5px;
}

.avatar {
	color: var(--white);
	font-weight: 600;
	position: relative
}

.avatar,
.avatar .picture,
.avatar i {
	align-items: center;
	background: var(--white);
	display: flex;
	font-size: 15px;
	height: 35px;
	justify-content: center;
	object-fit: cover;
	user-select: none;
	width: 35px;
	word-break: normal
}

.avatar:has(img.picture) {
	background: unset
}

.avatar.uiUserPicture {
	border-radius: 50%
}

.avatar.uiUserPicture .picture {
	border-radius: 50%;
	box-shadow: 0 0 0 2px var(--white)
}

.avatar.uiTeamPicture {
	border-radius: 5px
}

.avatar.uiTeamPicture .picture,
.avatar.uiTeamPicture i {
	border: 1px solid var(--border-grey-light);
	border-radius: 5px
}

.avatar.uiTeamPicture i {
	font-weight: 400
}

.avatar.uiTeamPicture.editable:hover {
	position: relative
}

.avatar.uiTeamPicture.editable:hover:after {
	align-items: center;
	background-color: rgba(var(--black), .5);
	border-radius: 5px;
	color: var(--white);
	content: "\f093";
	cursor: pointer;
	display: flex;
	font-family: "Font Awesome 6 Free";
	font-weight: 900;
	inset: 0;
	justify-content: center;
	position: absolute
}

.avatar.sm,
.avatar.sm .picture,
.avatar.sm i {
	font-size: 12px;
	height: 25px;
	line-height: 25px;
	width: 25px
}

.avatar.lg,
.avatar.lg .picture,
.avatar.lg i {
	font-size: 25px;
	height: 40px;
	line-height: 40px;
	width: 40px
}

.avatar.lg .picture {
	transform: translateY(-1px)
}

.avatar.xl,
.avatar.xl .picture,
.avatar.xl i {
	font-size: 60px;
	height: 100px;
	line-height: 100px;
	width: 100px;
	min-width: 100px;
}

.avatar.xl .picture {
	transform: translateY(-4px)
}

.avatarPrimary0 {
	background: var(--primary-color)
}

.avatarPrimary1 {
	background: var(--tertiary1-color)
}

.avatarPrimary2 {
	background: var(--tertiary2-color)
}

.avatarPrimary3 {
	background: var(--secondary-color)
}

.avatarPrimary4 {
	background: var(--secondary-color-500)
}

.avatarPrimary5 {
	background: var(--secondary-color)
}

.avatarPrimary6 {
	background: var(--reports-bounced-color-400)
}

.avatarPrimary7 {
	background: var(--reports-unsubscribed-color-300)
}

.avatarPrimary8 {
	background: var(--primary-color)
}

.avatarPrimary9 {
	background: var(--alert)
}

.avatarSecondary0 {
	background: var(--primary-color-100)
}

.avatarSecondary0 i {
	color: var(--primary-color)
}

.avatarSecondary1 {
	background: var(--tertiary1-color-100)
}

.avatarSecondary1 i {
	color: var(--tertiary1-color)
}

.avatarSecondary2 {
	background: var(--tertiary2-color-100)
}

.avatarSecondary2 i {
	color: var(--tertiary2-color)
}

.avatarSecondary3 {
	background: var(--secondary-color-100)
}

.avatarSecondary3 i {
	color: var(--secondary-color)
}

.avatarSecondary4 {
	background: var(--reports-skipped-color-100)
}

.avatarSecondary4 i {
	color: var(--reports-skipped-color-300)
}

.avatarSecondary5 {
	background: var(--secondary-color-100)
}

.avatarSecondary5 i {
	color: var(--secondary-color)
}

.avatarSecondary6 {
	background: var(--reports-bounced-color-100)
}

.avatarSecondary6 i {
	color: var(--reports-bounced-color-300)
}

.avatarSecondary7 {
	background: var(--reports-unsubscribed-color-100)
}

.avatarSecondary7 i {
	color: var(--reports-unsubscribed-color-300)
}

.avatarSecondary8 {
	background: var(--primary-color-100)
}

.avatarSecondary8 i {
	color: var(--primary-color)
}

.avatarSecondary9 {
	background: var(--alert-100)
}

.avatarSecondary9 i {
	color: var(--alert)
}

.fs-16 {
	font-size: 16px;
}

.notificationDropDown.show {
	display: flex;
	flex-direction: column;
	max-height: 650px;
	padding: 16px 0 0;
	width: 415px;
	margin-right: -50px;
	margin-top: 10px;

	.notificationWrapper {
		flex: 1;
		overflow-y: auto;
		padding: 0;
		position: relative;

		.noNotificationContainer {
			align-items: center;
			display: flex;
			flex-direction: column;
			gap: 32px;
			padding-bottom: 56px;
			padding-top: 40px;

			.noNotificationMessageContainer {
				background-color: var(--background-grey-medium);
				border-radius: 10px;
				color: var(--text-standard);
				padding: 8px 16px;

				span {
					align-items: center;
					display: flex;
					gap: 8px;
					font-size: 14px;

					i {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.userDropDownMenu {
	width: 328px;

	.dropdownSubMenu {
		padding: 16px;
		border-bottom: 1px solid var(--border-grey-light);

		.dropdownMenuItemUser {
			align-items: center;
			display: flex;
			font-size: 14px;
			gap: 16px;
			color: var(--text-light);
			cursor: pointer;

			&:not(:last-of-type) {
				margin-bottom: 16px;
			}

			&.logout {
				color: var(--alert);
				justify-content: space-between;
			}

			.name {
				font-weight: 600;
			}

			.user-login {
				color: var(--text-inactive);
				font-size: 14px;
				text-wrap: nowrap;
				text-overflow: ellipsis;
				width: 246px;
				overflow: hidden;
			}
		}
	}
}

.dropdown-menu-arrow {
	padding: 0;
}

input.rsInputFile.form-control {
	cursor: pointer;
	opacity: 0;
	position: absolute;
}

.input-group {
	.input-group-text {
		background-color: transparent;
		border-left: none;

		.material-icons-outlined {
			line-height: 1;
			font-size: 20px;
		}

		.showPasswordWrapper {
			display: flex;
			cursor: pointer;
		}
	}

	[type=password] {
		border-right: none;
	}

	.input-group{
		&:has(.input-group-text){
			.form-control{
				border-right: none;
			}
		}
	}

	.form-control {
		min-height: 45px;
		box-shadow: none;
		background-color: transparent;

		&.disabled {
			background: var(--background-disable);
		}
	}
}

.position-relative {
	.form-label {
		background: var(--white);
		border-radius: 8px;
		color: var(--text-light);
		font-size: 12px;
		font-weight: 500;
		left: 8px;
		line-height: 14px;
		padding: 0 6px;
		position: absolute;
		top: -10px;
		z-index: 9;
	}
}

.w-75 {
	width: 75% !important;
}

.textMuted {
	color: #6c757d !important;
}

.primaryColor {
	color: var(--primary-color)
}

.checkbox {
	display: block;
	position: relative;
	white-space: nowrap;

	label {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
		margin: 0;
		user-select: none;

		input[type=checkbox] {
			appearance: none;
			background: var(--white);
			border-radius: 4px;
			box-shadow: inset 0 0 0 1px #b7bec6;
			cursor: pointer;
			display: inline-block;
			height: 20px;
			line-height: 0;
			outline: none;
			width: 20px;

			&+span {
				margin-left: 8px;
			}

			&:checked {
				&:before {
					background: var(--primary-color);
					opacity: 1;
					transform: scale(1);
				}
			}

			&:before {
				border-radius: 4px;
				color: var(--white);
				content: "\f00c";
				align-items: center;
				background: transparent;
				display: flex;
				font-size: 12px;
				height: 20px;
				justify-content: center;
				line-height: 20px;
				opacity: 0;
				position: relative;
				transform: scale(0);
				transition: all .2s ease-in-out;
				width: 20px;
				font-family: "Font Awesome 6 Free";
				font-weight: 900;
			}
		}
		> input{
			&:disabled{
				background: var(--border-grey-light);
				border: 1px solid var(--border-disabled);
				box-shadow: inset 0 0 0 1px var(--border-grey-light);
				cursor: not-allowed;
			}
		}
	}
}

.toolTipCustom .tooltip .tooltip-inner {
	--bs-tooltip-max-width: 330px;
	text-align: left;
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.uiSection {
	background: var(--background-grey-medium);
	border-radius: 10px;
	display: flex;
	flex-flow: column nowrap;

	.header {
		.title{
			align-items: center;
			color: var(--text-standard);
			display: flex;
			font-weight: 600;
			gap: 6px;
		}
	}

	&.sm {
		.content {
			padding: 8px;
		}
	}

	.content {
		flex: 1;
		padding: 16px;
		> *{
			margin: 0;
		}
	}

	&.shadow {
		box-shadow: 0 0 6px rgba(0, 0, 0, .02), 0 2px 4px rgba(0, 0, 0, .08) !important;
	}

	&.mvp {
		border: 1px solid var(--border-grey-light);

		.header{
			padding: 8px 16px;
			&+ .content{
				border-radius: 0 0 10px 10px;
			}
		}

		.content {
			background: var(--white);
			border-radius: 10px;
		}
	}

	&.gmailProvider,
	&.outlookProvider {
		background: var(--background-grey-medium);
		height: 200px;
		position: relative;

		&>.content {
			background: transparent !important;
			display: flex;
			border-radius: 10px;
		}

		&:before {
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: 163px 36px;
			content: "";
			inset: 0;
			position: absolute;
		}

		&.errorWarning{
			&::before{
				background-position: 50% 33%;
			}
		}

	}

	&.gmailProvider {
		&::before {
			background-image: url(../images/gmail-small.svg);
		}
	}

	&.outlookProvider {
		&::before {
			background-image: url(../images/office-365-small.svg);
		}
	}
}

.uiStepper {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	width: auto;

	input {
		appearance: textfield !important;
		background: var(--white) !important;
		border: 0 !important;
		border-radius: 0 !important;
		box-shadow: inset 0 0 0 1px var(--border-grey-light) !important;
		color: var(--black) !important;
		height: 40px !important;
		text-align: center !important;
		width: 50px !important;
		min-height: 40px !important;
		padding: 0 !important;

		&:disabled{
			background: var(--background-disable) !important;
			box-shadow: inset 0 0 0 1px var(--text-inactive);
			color: var(--text-inactive) !important;
			opacity: .65 !important;
		}

	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
}

i.fa-solid {
	font-size: 16px;
	text-align: center;
}

.d-grid {
	--gutter: 16px;
	--cols: 2;
	display: grid;
	gap: var(--gutter);
	grid-template-columns: repeat(var(--cols), 1fr);
	width: 100%;

	&.col3 {
		--cols: 3;
	}
}

.grey {
	color: #858585;
}

.rsSearchInput {
	align-items: center;
	background: var(--white);
	border: 1px solid var(--border-grey-light);
	border-radius: 8px;
	color: var(--text-inactive);
	display: flex;
	gap: 10px;
	height: 45px;
	padding: 14.5px;

	input {
		appearance: none;
		background: transparent;
		border: 0;
		box-shadow: none !important;
		color: var(--black);
		flex: 1;
		padding: 0;
		font-size: 14px;
	}

	&:has(input:not(:placeholder-shown)) {
		.rsClearSearch {
			display: block;
		}
	}

	&:has(input:focus),
	&:has(input:active) {
		border: 1px solid var(--primary-color);
		box-shadow: 0 0 0 3px rgba(49, 107, 255, .15);
	}

	.rsClearSearch {
		cursor: pointer;
		display: none;
	}
}

.dropdownTrigger {
	cursor: pointer;
}

.checkbox {
	display: block;
	position: relative;
	white-space: nowrap;

	label {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
		margin: 0;
		user-select: none;
	}

	input {
		appearance: none;
		background: var(--white);
		border-radius: 4px;
		box-shadow: inset 0 0 0 1px #b7bec6;
		cursor: pointer;
		display: inline-block;
		height: 20px;
		line-height: 0;
		outline: none;
		width: 20px;
		margin-top: 0;

		&:before {
			align-items: center;
			background: transparent;
			color: var(--white);
			display: flex;
			font-size: 12px;
			height: 20px;
			justify-content: center;
			line-height: 20px;
			opacity: 0;
			position: relative;
			transform: scale(0);
			transition: all .2s ease-in-out;
			width: 20px;
		}

		&:not(:disabled):not(.disabled):checked,
		&:not(:disabled):not(.disabled)[type=checkbox]:indeterminate {
			box-shadow: inset 0 0 0 1px var(--primary-color);

			&:before {
				background: var(--primary-color);
			}
		}

		&:checked {
			&::before {
				background: var(--primary-color);
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	&.rsCheckbox {
		border-radius: 4px;

		input {
			&::before {
				border-radius: 4px;
				color: var(--white);
				content: "\f00c";
				font-family: "Font Awesome 6 Free";
			}
		}

		&.bordered{
			background: var(--white);
			border: 1px solid var(--border-grey-light);
			border-radius: 10px;
			padding: 8px;
		}
	}

	&.rsRadio{
		input{
			border-radius: 50%;
			&:before{
				background: var(--primary-color);
				border-radius: 50%;
				content: " ";
				height: 14px;
				margin: 3px;
				width: 14px;
			}
		}
	}
}

.relative {
	position: relative;
}

.campaignStateCampaignSettingsModal {
	align-items: baseline;
	display: flex;
}

.cursor-pointer {
	cursor: pointer;
}

.textInactive {
	color: var(--text-inactive);
}

.customControl {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}

.customSwitch {
	padding-left: 2.25rem;
}

.customControlLabel {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
}

.rsTable {
	.tableRow {
		align-items: center;
		border-bottom: 1px solid var(--border-grey-light);
		padding: 16px;
	}
	.tableRows{
		border: 1px solid var(--border-grey-light);
	}
}

.rsInfoMsg {
	align-items: center;
	border-radius: 10px;
	display: flex;
	font-weight: 500;
	padding: 8px 16px;
	width: 100%;

	&::before {
		display: inline-block;
		font-size: 16px;
		font-weight: 400;
		margin-right: 8px;		
	}

	&.disabled {
		background-color: var(--background-grey-medium);

		&::before {
			color: var(--text-inactive);
			content: "\f05a";
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
		}
	}

	&.warning{
		background-color: var(--warning-100);
		&::before{
			color: var(--warning);
			content: "\f071";
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
		}
	}

	&.info{
		background-color: var(--information-100);

		&::before {
			color: var(--information);
			content: "\f05a";
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
		}
	}
}

.textEdit {
	position: relative;

	.inputWrapper {
		align-items: center;
		display: flex;
		gap: 8px;
		padding: 10px 10px 10px 16px;
		position: relative;
		z-index: 1;

		&:has(input) {
			height: 45px;
		}

		button{
			z-index: 1;
		}

		.rsTextEditBorder {
			background: var(--white);
			border: 1px solid var(--border-grey-light);
			border-radius: 10px;
			cursor: pointer;
			inset: 0;
			pointer-events: none;
			position: absolute;
			&.alert{
				border: 1px solid var(--alert);
				margin-bottom: 0;
			}
		}

		input{
			&:focus:not(:placeholder-shown)~.rsClear{
				cursor: pointer;
    			opacity: 1;
				z-index: 1;
			}
		}

		input,
		input::placeholder,
		textarea,
		i {
			color: var(--text-inactive);
		}

		.rsClear{
			font-size: 12px;
			opacity: 0;
		}

		&:has(input:disabled){
			input,
			.rsTextEditBorder{
				background-color: var(--background-disable);
			}
		}

		input,
		textarea {
			appearance: none;
			background: var(--white);
			border: 0;
			box-shadow: none !important;
			outline: none !important;
			flex: 1 1 auto;
			line-height: 20px;
			padding: 0;
			resize: none;
			width: 100%;
			z-index: 1;

			&:not(:placeholder-shown) {
				color: var(--text-standard);
			}

			&:active:not(:disabled),
			&:focus:not(:disabled),
			&:active:not(:disabled),
			&:focus:not(:disabled) {
				~.rsTextEditBorder {
					&:not(.alert):not(.valid) {
						border: 1px solid var(--primary-color);
						box-shadow: 0 0 0 3px var(--primary-color-100);
					}
				}
			}
		}
	}

	label{
		background: var(--white);
		border-radius: 8px;
		color: var(--text-light);
		font-size: 12px;
		font-weight: 500;
		left: 8px;
		line-height: 14px;
		padding: 0 4px;
		position: absolute;
		top: -10px;
		z-index: 2;
	}
}

.sequenceEditor {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 0;

	&.backgroundDotted {
		background: var(--background-dotted);
	}
}

.createManuallyContainer {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;

	.firstStepContainer {
		box-shadow: 0 0 6px rgba(0, 0, 0, .02), 0 2px 4px rgba(0, 0, 0, .08);
	}
}

.firstStepContainer {
	background: var(--white);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	width: 1186px;
}

.semiBold {
	font-weight: 500;
}

.bold {
	font-weight: 600;
}

.textLight {
	color: var(--text-light) !important;
}

.btn {
	&.rsSwitchBox {
		background-color: var(--primary-color);
		border-radius: 10px;
		color: var(--white);
		gap: 0 !important;
		height: 45px;
		padding: 2px !important;

		&:not(.checked) {
			span,
			a {
				&:first-child {
					background-color: var(--white);
					color: var(--primary-color);

					&.textLabel {
						background: var(--white);
						color: var(--text-standard);
					}
				}
			}
		}

		&.checked {
			span,
			a {
				&:last-child {
					background-color: var(--white);
					color: var(--primary-color);

					&.textLabel {
						background: var(--white);
						color: var(--text-standard);
					}
				}
			}
		}
		span,
		a {
			align-items: center;
			border-radius: 8px;
			display: flex;
			gap: 8px;
			height: 41px;
			justify-content: center;
			padding: 10px;

			&.textLabel {
				background: var(--white);
				color: var(--text-standard);
				flex: 1;
				font-size: 16px;
				padding: 16px;
			}
		}

		&.secondary {
			background-color: var(--white);
			border: 1px solid var(--border-grey-light);
			color: var(--text-standard);

			&:not(.checked) {
				span,
				a {
					&:first-child {
						background: radial-gradient(163.33% 163.33% at 50% 100%, hsla(0, 0%, 100%, .45) 0, transparent 100%, hsla(0, 0%, 100%, 0) 100%), var(--primary-color);
						background-blend-mode: overlay, normal;
						border: 1px solid var(--btn-border-color);
						box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
						color: var(--always-white);

						&.textLabel {
							border: 1px solid var(--btn-border-color);
							box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
							color: var(--white);
							outline: 0;
						}
					}
				}
			}

			&.checked {
				span,
				a {
					&:last-child {
						background: radial-gradient(163.33% 163.33% at 50% 100%, hsla(0, 0%, 100%, .45) 0, transparent 100%, hsla(0, 0%, 100%, 0) 100%), var(--primary-color);
						background-blend-mode: overlay, normal;
						border: 1px solid var(--btn-border-color);
						box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px --btn-shadow-color;
						color: var(--always-white);

						&.textLabel {
							border: 1px solid var(--btn-border-color);
							box-shadow: inset 0 0 0 1px var(--btn-outline-color), 0 3px 4px var(--btn-shadow-color);
							color: var(--white);
							outline: 0;
						}
					}
				}
			}
		}
	}

}

.email {
	border-radius: 4px;
	color: #414141;
	font-family: sans-serif;
	font-size: 14px;
	line-height: 1.6;
}

body {

	.rsDropdown {
		&:not(.ai) {
			.btn.btnDropdown {
				background: var(--white);
				font-weight: 400;
				height: 45px;
				padding: 8px 34px 8px 16px;

				>* {
					padding: 0;
				}

				.dropdownValue {
					align-items: center;
					display: flex;
					flex: 1;
					gap: 8px;
					overflow: hidden;
					text-align: left;
					text-overflow: ellipsis;

					span {
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.placeholder {
						color: var(--text-inactive);
					}
				}

				span {
					-webkit-text-fill-color: unset;
					background: unset;
					color: var(--text-standard);
				}

				&.alert{
					box-shadow: inset 0 0 0 1px var(--alert);
					margin: 0;
				}

			}
		}

		.sendersPicture{
			.avatar{
				font-size: 14px;
				height: 30px;
				width: 30px;
			}
			.picture{
				width: 30px;
				height: 30px;
			}
		}
	}

	.btn.btnDropdown {
		-webkit-text-fill-color: unset;
		width: 100%;
		color: var(--text-standard);
		box-shadow: inset 0 0 0 1px var(--border-grey-light);

		&:after {
			color: var(--text-standard);
			content: "\f078";
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
			position: absolute;
			right: 16px;
		}
	}
}

.dropdownPicker {
	background: var(--white);
	border: 1px solid var(--primary-color);
	border-radius: 10px;
	box-shadow: 0 0 4px rgba(0, 0, 0, .04), 0 8px 16px rgba(0, 0, 0, .08);
	font-size: unset;
	margin: 8px 0 0;
	max-height: 300px;
	min-width: 100%;
	overflow: hidden;
	overflow-y: scroll;
	padding: 0;
	position: absolute;
	z-index: 1000;

	.uiDropdownHeader {
		padding: 8px 16px;
		pointer-events: none;
		user-select: none;
		color: var(--text-inactive);
		font-size: 11px;
		text-transform: uppercase;
		&.multiSenderHeader{
			align-items: center;
			border-top: 1px solid var(--border-grey-light);
			display: flex;
			font-size: 12px;
			font-weight: 600;
			gap: 8px;
		}
	}

	~ .uiDropdownItem{
		padding: 8px 8px 8px 32px;
	}

	.uiDropdownItem{
		align-items: center;
		color: var(--text-standard);
		display: flex;
		gap: 0!important;
		padding: 8px 16px;
		white-space: nowrap;
		width: 100%;
		.rsCheckbox{
			margin-right: 8px;
		}
		&:not(.empty-text):not(.disabled){
			cursor: pointer;
		}
		&:not(.empty-text){
			&:hover{
				background: var(--background-grey-light);
			}
			&:active{
				background: var(--background-grey-medium);
			}
			&.selected{
				background: var(--primary-color-100);
				color: var(--text-standard);
			}
		}
	}
}

.modal-content{
	.btnModalClose {
		position: absolute;
		right: 16px;
		top: 16px;
		z-index: 1;
	}
}

@media (max-height: 850px){
	.rsImportArea {
		height: 250px!important;
	}
}

.pointer-events-none {
    pointer-events: none;
}

.fakeSection {
    background: var(--white);
    border-radius: 10px;
}

.height100Pixels {
    height: 100px;
}

.rsTable{

	&.settingsTable{
		background: var(--white);
		border: 1px solid var(--border-grey-light);
		border-radius: 10px;

		&+ .settingsTable{
			margin-top: 16px;
		}

		.header{			
			border-bottom: 0;
			font-size: 12px;
			font-weight: 600;
		}

		.header,
		.tableRows{
			border-bottom: 0;
			border-left: 0;
			border-radius: 0;
			border-right: 0;
			margin-bottom: 0;
		}

		.header,
		.tableRow{
			column-gap: 20px;
		}

		.name,
		.userEmail{
			max-width: 160px;
			text-wrap: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.team{
		&.agency{
			.header,
			.tableRow{
				grid-template-columns: 200px 1fr 1fr 120px 50px 90px 32px;
			}
			.header{
				.headerItem{
					> span{
						max-width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		.preHeader{
			.licenses{
				border-top: 1px solid var(--border-grey-light);
				font-size: 11px;
				padding: 16px;
				.licensesGauge{
					background: #b7bec6;
					border-radius: 100px;
					font-weight: 600;
					height: 6px;
					margin-bottom: 8px;
					width: 500px;
					.gaugeFill{
						background: var(--primary-color);
						border-radius: 100px;
						height: 6px;
					}
				}
				.licensesCount {
					color: var(--primary-color);
				}
			}
			.editWrapper{
				display: flex;
				flex: 1;
				gap: 16px;
			}
		}

		.userCrms{
			.crmIcon{
				height: 16px;
    			width: 16px;
				&.off{
					opacity: 15%;
				}
			}
		}

		.email{
			.emailProvider{
				color: var(--text-inactive);
				font-size: 12px;
				font-weight: 500;
				padding-bottom: 4px;
				text-transform: uppercase;
			}
					
		}

		.email,
		.linkedin{
			.accountConnected,
			.emailStatus,
			.notConnected{
				color: var(--text-standard);
				font-size: 14px;

				&::before{
					content: "\f111";
					font-family: "Font Awesome 6 Free";
					font-size: 8px;
					font-weight: 900;
					margin-right: 4px;
				}
				&.on{
					&::before{
						color: var(--success);
					}
				}
			}

			.notConnected{
				color: var(--text-inactive);
			}
		}
	}

	.preHeader{
		.d-flex-row{
			padding: 8px 16px;
			&.rsCollapseTable{
				cursor: pointer;
			}
		}
		.rsEditTeamName{
			color: var(--text-inactive);
		}
	}

	.header,
	.tableRows{
		border-radius: 10px;
	}

	.header,
	.tableRow{
		background: var(--white);
		display: grid;
	}

	.header{
		align-items: center;
		border: 1px solid var(--border-grey-light);
		font-size: 12px;
		font-weight: 600;
		padding: 8px 16px;
	}
	
	.rsSortable:not(.allow-wrap)>span,
	.header>.headerItem:not(.allow-wrap):not(.rsSortable){
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.tableRow{
		align-items: center;
		border-bottom: 1px solid var(--border-grey-light);
		padding: 16px;

		&:first-child{
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}

		&:last-child{
			border: 0;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		>.tableRowItem{
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		>.tableRowItemFlex{
			align-items: center;
			display: flex;
			gap: 8px;
			justify-self: flex-start;
		}
	}
}

.cursorPointer{
	cursor: pointer;
}

.width45Percents{
	width: 45%;
}

.width40Percents{
	width: 40%;
}

.width70Percents{
	width: 70%;
}

.rsTag{
	align-items: center;
    border-radius: 5px;
    color: var(--white);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    height: fit-content;
    padding: 6px 8px;
    width: fit-content;
	&.sm{
		font-size: 12px;
    	padding: 2px 4px;
	}
	&.tagSuccess100{
		background-color: var(--success-100);
    	color: var(--success);
	}

	&.tagWarning100{
		background-color: var(--warning-100);
   	color: var(--warning);
	}
}

.toggleLeadState{
	align-items: center;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 5px 8px;
	&.ended,
	&.paused,
	&.running{
		background: var(--primary-color-100);
	}
	&.review,
	&.unsubscribed{
		background: var(--background-grey-medium)
	}
	&.interested{
		background: var(--reports-interested-color-100)
	}
	&.notInterested{
		background: var(--reports-notinterested-color-100)
	}
	&.scanning{
		background: var(--background-grey-medium)
	}
}

.leadSummary{
	&.md{
		&.d-flex-row{
			gap: 8px;
		}
		.leadPictureAndState{
			width: 40px;

			.leadPicture{
				height: 40px;
				width: 40px;
			}
		}

		.leadContact{
			.leadName,
			p{
				font-size: 14px;
    			font-weight: 500;
				margin-bottom: 0;
			}
		}
	}

	&.sm{
		.leadPictureAndState{
			width: 25px;
		}
	}

	.leadPictureAndState{
		position: relative;
		width: 55px;

		.hoverLayer{
			align-items: center;
			background: hsla(0,0%,98%,.8);
			border-radius: 100%;
			display: none;
			height: 100%;
			justify-content: center;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 100%;
		}
	}

	&.displayEye{
		&:hover{
			.hoverLayer{
				display: flex;
			}
		}
	}

	.leadContact{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.leadHeader{
			display: flex;
			gap: 5px;
		}

		.leadName{
			align-items: center;
			display: inline-flex;
			font-size: 18px;
			font-weight: 600;
			gap: 8px;
			overflow: hidden;

			.leadNameOverflow{
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.alertColor {
	color: var(--alert);
}

.desktop * {
	box-sizing: border-box;
}

.settingsUpperModal{
	&.provider{
		--bs-modal-width: 716px;
	}
}

.modal{
	.modal-dialog{
		.modal-body{
			border-radius: 10px;
    		padding: 0 16px;
		}
		&.campaignSettingModal{
			.rsAutoPausedLeads{
				padding: 16px;
				white-space: normal;
				width: 70%;
			}

			.modal-content{
				margin: auto;
				max-height: 850px;
				width: 1100px;
			}

			.editScheduleTemplate{
				background: var(--background-grey-light);
				border: 1px solid var(--border-grey-light);
				border-radius: 10px;
				padding: 16px;
				h4.bold{
					margin-bottom: 8px;
				}
				.scheduleTimezone,
				.rsScheduleName{
					width: 500px;
				}

				.scheduleTimezone{
					[class$="control"]{
						background: var(--white);
						font-weight: 400;
						height: 45px;
						padding: 4px 16px 4px 16px;
						line-height: 1;
						box-shadow: inset 0 0 0 1px var(--border-grey-light);
						border: none;
						border-radius: 10px;
					}
					span[class$="indicatorSeparator"] {
						display: none;
					}
					[class$="singleValue"]{
						margin:0;
						overflow: hidden;
						text-align: left;
						text-overflow: ellipsis;
					}
					[class$="Input"]{
						padding: 0;
						margin: 0;
					}
				}

				.scheduleWeeks{
					background: var(--white);
					border: 1px solid var(--border-grey-light);
					border-radius: 10px;
					flex-flow: wrap;
					padding: 16px;
					gap: 15px;

					input + span{
						font-weight: 500;
						margin-left: 4px;
					}
				}

				.containerReachLeads{
					display: grid;
					gap: 16px;
					grid-template-columns: 180px 180px 1fr;

					> div:last-child{
						padding-left: 24px;
					}

					.uiStepper{
						display: inline-flex;
					}
				}

				.rsInfoMsg{
					width: fit-content;
				}

				.btn{
					align-self: self-start;
					z-index: 0;
				}
			}
		}
		&.wide{
			@media(min-width: 1200px){
				max-width: 1200px;
			}

			@media(min-width: 576px){
				max-width: 80%;
			}
		}

		&.searchListStyleModal{
			&.emailPreviewModal{
				.modal-content{
					max-height: 80vh;
					.modal-body{
						.modalLeft{
							width: 300px;
						}
						.modalRight{
							overflow: auto;
						}
					}
				}
			}
			.modal-content{
				.modal-body{
					flex-direction: row;
					height: 100%;
					overflow: hidden;
					padding: 0;
					.modalLeft{
						background: var(--white);
						border-right: 1px solid var(--border-grey-light);
						display: flex;
						flex-direction: column;
						width: 360px;
						.header{
							padding: 24px 24px 16px;
						}
						.resultsList{
							flex: 1;
							overflow-y: auto;
							.summary{
								border-bottom: 1px solid var(--border-grey-light);
								color: var(--text-inactive);
								padding: 8px 16px;
							}
							.list{
								> .item{
									border-top: 1px solid var(--border-grey-light);
								}
								.item{
									align-items: center;
									border-bottom: 1px solid var(--border-grey-light);
									cursor: pointer;
									display: flex;
									font-weight: 500;
									height: 35px;
									padding: 10px 16px;
									
									&.active,
									&:hover{
										background: var(--primary-color-100);
										border-left: 3px solid var(--primary-color);
										color: var(--text-standard);
										font-weight: 600;
										padding-left: 16px;

										.expander{
											margin-right: -3px;											
										}
									}

									span{
										display: inline-block;
										flex: 1;
										max-width: 100%;
										overflow: hidden;
										transition: max-width .1s ease-in-out;
										white-space: nowrap;
									}
									.expander{
										display: grid;
										font-weight: 600;
										height: 16px;
										place-items: center;
										width: 16px;
										&::after{
											content: "\f0d8";
											font-family: "Font Awesome 6 Free";
											font-weight: 900;
										}
										&.right{
											&::after{
												content: "\f0da";
											}
										}
									}

									&.expanded{
										.expander{
											&::after{
												content: "\f0d7";
											}
										}

										&+ .subItems{
											max-height: 5000px;
											transition: max-height .25s ease-in;
										}
									}

									&+ .subItems{
										max-height: 0;
										overflow: hidden;
										transition: max-height .25s ease-out;
									}
								}
							}
						}

						.rsDefaultLead{
							&+ .subItems{
								padding: 0 32px;
							}
							&.expanded{
								&+ .subItems{
									padding: 16px 32px;
								}
							}
						}
					}

					.modalRight{
						display: flex;
						flex: 1;
						flex-direction: column;
						// height: 100%;
						overflow: hidden;
						position: relative;

						.emailTemplateSender{
							width: 400px;
						}

						.modalCenter{
							flex: 1;
							overflow: hidden;
							overflow-y: auto;
							padding: 32px 32px 16px;

							.title{
								border-bottom: 1px solid var(--border-grey-light);
								padding-bottom: 16px;
							}

							.emailTemplatePreview{
								background: var(--background-grey-light);
								border: 1px solid var(--border-grey-light);
								border-radius: 10px;
								overflow: auto;
								padding: 16px;

								&+ .emailTemplatePreview{
									flex: 1;
									p, ul{
										margin: 0;
									}
									ul{
										padding-left: 1.5rem;
									}
								}
							}

							.mobile{
								align-self: center;
								// height: 926px;
								// overflow: auto;
								width: 428px;
							}
						}
					}
				}
				.previewModalFooter{
					align-items: center;
					border-top: 1px solid var(--border-grey-light);
					box-shadow: 0 -4px 20px rgba(0,0,0,.05);
					display: flex;
					justify-content: flex-end;
					padding: 16px 24px;
					.input-group{
						width: unset;
						.form-control{
							min-height: auto;
						}
					}
				}
			}
		}
	}
	.modal-content{
		.modal-body{
			border-radius: 10px;
			display: flex;
			min-height: 0;
			overflow: auto;
			padding: 16px;
		}
	}
	.tabStyleModal{
		&.campaignLeadsImportModal{
			@media (min-width: 1200px){
				--bs-modal-width: 1200px;
			}

			.modal-content{
				max-height: 750px!important;
				box-shadow: 0 0 4px rgba(0,0,0,.04),0 8px 16px rgba(0,0,0,.08),0 4px 0 var(--modal-color);
				margin: auto;
    			width: 1170px;
				.modal-body{
					.modalRight{
						height: 750px !important;
					}
				}
			}
		}

		.modal-content{
			.modal-body{
				flex-direction: row;
				height: 100%;
				overflow: hidden;
				padding: 0;

				.modalLeft{
					background: var(--background-grey-light);
					border-right: 1px solid var(--border-grey-light);
					display: flex;
					flex: 0 0 300px;
					flex-direction: column;
					gap: 32px;
					padding-top: 32px;

					.modalLeftHeading{
						font-size: 20px;
						font-weight: 600;
						padding: 0 32px;
					}
					.modalLeftItemsWrapper{
						display: flex;
						flex: 1;
						flex-direction: column;
						margin-bottom: 30px;

						.modalLeftItemWrap{
							align-items: center;
							cursor: pointer;
							display: flex;
							font-size: 16px;
							font-weight: 500;
							gap: 8px;
							height: 55px;
							padding: 16px 16px 16px 32px;
							position: relative;

							&.active{
								&:not(.disabled){
									background: var(--primary-color-100);
									box-shadow: inset 5px 0 0 0 var(--primary-color);
									color: var(--primary-color);
									position: relative;
								}
							}

							&.disabled{
								background-color: var(--background-disable);
								cursor: default !important;
								filter: grayscale(100%);
								pointer-events: none;
							}

							img{
								height: 16px;
								width: 16px;
							}
						}
					}
				}

				.modalRight{
					display: flex;
					flex: 1;
					flex-direction: column;
					height: 100%;
					overflow: hidden;
					position: relative;

					.modalTop{
						border-bottom: 1px solid var(--border-grey-light);
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						margin: 0 32px;
						padding: 32px 0 16px;

						.title{
							align-items: center;
							align-self: start;
							display: flex;
							font-size: 20px;
							font-weight: 600;
							gap: 8px;
							justify-content: center;
						}
					}

					.modalCenter{
						display: flex;
						flex: 1;
						flex-direction: column;
						overflow: hidden;
						overflow-y: auto;
						padding: 32px 32px 16px;
					}

					.modalButtons{
						border-top: 1px solid var(--border-grey-light);
						padding: 16px 32px;

						.buttonInfoText{
							align-items: center;
							color: var(--text-light);
							display: flex;
							gap: 8px;
							margin-right: 8px;
						}

						.rsContinue{
							min-width: 160px;
						}
					}

					.importCSVData{
						position: relative;

						.rsImportDataClear{
							color: var(--text-inactive);
							cursor: pointer;
							position: absolute;
							right: 16px;
							top: 14px;
						}

						.importProgressContainer{
							background: var(--background-grey-light);
							border-radius: 50px;
							height: 10px;
							position: relative;
							
							.importProgressBar{
								background: var(--success);
								border-radius: 50px;
								height: 10px;
								left: 0;
								position: absolute;
								top: 0;
								transition: width .2s ease;
								width: 50%;
							}
						}

						.importProgressPercentage{
							text-align: right;
							width: 40px;
						}
					}
				}
			}
		}		
	}
	.activityMessageModal{
		--bs-modal-width: 700px;
		height: 500px;
		.modal-body{
			display: flex;
			flex: 1;
			flex-direction: column;
			height: 100%;
			overflow: hidden;
			position: relative;

			.emailHeaders,
			.activityMessageTitle{
				border-bottom: 1px solid var(--border-grey-light);
				margin: 0 16px;
				padding: 16px 0;
			}

			.activityMessageBody{
				font-weight: 500;
				overflow: auto;
				padding: 16px 16px 0;
				max-height: 250px;
				p, ul{
					margin: 0;
				}
				ul{
					padding-left: 1.5rem;
				}
			}

			.activityMessageTitle{				
				font-size: 20px;
				font-weight: 600;
			}
		}
	}
	.settingsUpperModal{
		&.fullHeight{
			height: calc(100vh - 160px);
			margin: 80px auto;
			--bs-modal-width: 800px;
			.modal-content{
				max-height: unset;
			}
		}
		.modal-content{
			.modal-body{
				display: flex;
				flex-direction: column;
				height: 100%;
				overflow: hidden;
				padding: 16px;
				.modalSettingsWrapper{
					display: flex;
					flex-direction: column;
					height: 100%;
					.modalSettingsHeader{
						display: flex;
						flex: 0 0 auto;
						flex-direction: column;
						gap: 4px;
						padding-bottom: 16px;
					}

					.modalSettingsScrollableContent{
						background: var(--background-grey-light);
						border: 1px solid var(--border-grey-light);
						border-radius: 10px;
						display: flex;
						flex: 1;
						flex-direction: column;
						gap: 24px;
						overflow: auto;
						padding: 24px;
					}
				}
			}
		}
	}
}


.spinBorder{
	display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
	0%{
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.deleteErrorModal,
.abTestModal,
.deleteWarningModal{
	--bs-modal-width: 550px;
}

.deleteErrorCampaignModal{
	--bs-modal-width: 650px;
}

.overlayLoadingWrapper{
	position: relative;
	.loaderSpinner{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 999;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.paddingRight10{
	padding-right: 10px;
}

.gap15{
	gap: 15px;
}

.gap30{
	gap: 30px;
}

.rsDeleteCampaign{
	padding-top: 16px !important;
	padding-bottom: 8px !important;
}

.warningColor{
	color: var(--warning);
}

.deleteWarningModal .modal-content {
    box-shadow: 0 0 4px rgba(0,0,0,.04),0 8px 16px rgba(0,0,0,.08),inset 0 -4px 0 var(--warning);
    padding-bottom: 3px;
    border-radius: 10px;

	h2{
		.fa-solid{
			font-size: inherit;
		}
	}
}

.deleteErrorModal .modal-content{
	box-shadow: 0 0 4px rgba(0,0,0,.04),0 8px 16px rgba(0,0,0,.08),inset 0 -4px 0 var(--alert);
	h2{
		.fa-solid{
			font-size: inherit;
		}
	}
}

.multiSender{
	.avatar{
		font-size: 14px;
		height: 30px;
		width: 30px;
	}
	.picture{
		width: 30px;
		height: 30px;
	}
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
	z-index: 9999;
}

.ql-snow{
	.ql-tooltip{
		z-index: 99999;
	}
}

.sent{
	&.bg{
		background: var(--reports-sent-color-300);
	}

	&.bg100{
		background: var(--reports-sent-color-100);
	}

	&.bg,
	&.bg100{
		color: var(--reports-sent-color-400);
	}

	&.color500{
		color: var(--reports-sent-color-400)
	}
}

.opened{
	&.bg{
		background: var(--reports-opened-color-300);
	}

	&.bg100{
		background: var(--reports-opened-color-100);
	}

	&.bg,
	&.bg100{
		color: var(--reports-opened-color-400);
	}

	&.color500{
		color: var(--reports-opened-color-400)
	}
}

.clicked{
	&.bg{
		background: var(--reports-clicked-color-300);
	}

	&.bg100{
		background: var(--reports-clicked-color-100);
	}

	&.bg,
	&.bg100{
		color: var(--reports-clicked-color-400);
	}

	&.color500{
		color: var(--reports-clicked-color-400)
	}
}

.replied{
	&.bg{
		background: var(--reports-replied-color-300);
	}

	&.bg100{
		background: var(--reports-replied-color-100);
	}

	&.bg,
	&.bg100{
		color: var(--reports-replied-color-400);
	}

	&.color500{
		color: var(--reports-replied-color-400)
	}
}

.interested{
	&.bg{
		background: var(--reports-interested-color-300);
	}

	&.bg100{
		background: var(--reports-interested-color-100);
	}

	&.bg,
	&.bg100{
		color: var(--reports-interested-color-400);
	}

	&.color500{
		color: var(--reports-interested-color-400)
	}
}

.pointer{
	cursor: pointer;
}

.activitiesLayoutRight{
	.emptyPlaceholder{
		margin: auto;
		text-align: center;
	}
}
.boxSizingBorderBox{
	box-sizing: border-box;
}

.settingsTableSearchBar{
	align-items: center;
    color: var(--text-standard);
    display: flex;
    font-weight: 500;
    gap: 16px;
    margin-bottom: 16px;
	.search{
		flex: 1;
		.rsSearchInput{
			max-width: 350px;
		}
	}
}

.pointerEventsNone{
	pointer-events: none;
}

.rsEditTeamName{
	cursor: pointer;
}

.email {
	border-radius: 4px;
	color: #414141;
	font-family: sans-serif;
	font-size: 14px;
	line-height: 1.6;
}

.teamMateModal{
	@media(min-width: 700px){
		--bs-modal-width: 630px;
	}
}

.avatar.lg2,
.avatar.lg2 .picture,
.avatar.lg2 i {
	font-size: 30px;
	height: 56px;
	line-height: 56px;
	width: 56px;
}

.avatar{
	.uiTeamPicture{
		.editable{
			&:hover{
				&::after{
					align-items: center;
					background-color: rgba(var(--black),.5);
					border-radius: 5px;
					color: var(--white);
					content: "\f093";
					cursor: pointer;
					display: flex;
					font-family: "Font Awesome 6 Free";
					inset: 0;
					justify-content: center;
					position: absolute;
				}
			}
		}
	}
}

.rsTable{
	&.settingsTable{
		&:not(.open){
			.footer,
			.header,
			.preHeader .licenses,
			.tableRows{
				display: none;
			}
		}
	}
}

#uploadFile{
	display: none;
}

.rsCheckbox{
	input:disabled{
		background: var(--border-grey-light);
		border: 1px solid var(--border-disabled);
		box-shadow: inset 0 0 0 1px var(--border-grey-light);
		cursor: not-allowed;
	}
}

.rsNewInfoMsg{
	width: 100%;
	.header,
	&.message{
		align-items: center;
		background: var(--information-100);
		border: 1px solid var(--border-grey-light);
		border-radius: 4px;
		display: flex;
		flex-flow: row nowrap;
		padding: 16px;
		gap: 16px;
		&::before{
			color: var(--information);
			content: "\f05a";
			display: inline-block;
			font-family: "Font Awesome 6 Free";
			font-size: 16px;
			font-weight: 900;
		}
	}

	.header{
		border: 1px solid #f2f2f2;
		border-bottom: 0;
		border-radius: 8px 8px 0 0;
		.title{
			color: var(--warning);
			font-weight: 500;
		}

		&+ .content{
			border: 1px solid var(--border-grey-light);
			border-radius: 0 0 4px 4px;
			border-top: 0;
			padding: 24px 16px;
		}
	}

	&.warning{
		.header,
		&.message{
			background: var(--warning-100);

			&::before{
				color: var(--warning);
				content: "\f071";
				font-weight: 900;
				font-family: "Font Awesome 6 Free";
			}
		}
	}
}

.link-underlined {
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: underline;
}

.rsRichTextArea{
	border: 1px solid var(--border-grey-light);
    border-radius: 10px;
    margin: 3px;
	.inputWrapper{
		.rsTextEditBorder{
			border: 0!important;
			border-radius: 10px 10px 0 0;
			box-shadow: none!important;
		}
	}

	.toolbar{
		align-items: center;
		background: var(--white);
		border-radius: 0 0 10px 10px;
		border-top: 1px solid var(--border-grey-light);
		display: flex;
		gap: 16px;
		justify-content: flex-end;
		padding: 8px 16px;
	}
}

.rsCustomToolTip {
    position: relative;
}

.tooltipOuterContent {
    z-index: 9999;
    visibility: visible;
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
}

.tippy-tooltip-content {
    width: 360px;
    background: var(--tooltip-background);
    border-radius: 8px!important;
    box-shadow: 0 2px 4px rgba(0,0,0,.11);
    color: var(--white);
    padding: 8px 16px;
}

.tipContentSvgArrow {
    fill: var(--tooltip-background);
    position: absolute;
    transform: rotate(180deg);
    height: 16px;
    text-align: initial;
    width: 16px;
}

.tippy-tooltip-content {
	&:has(table){
		width: 375px;
		display: flex;
		justify-content: center;
	}
	table {
		text-align: left;
		font-size: 14px;
		th{
			font-size: 13px;
		}
	}
}