.layoutLeftWrapper {
	background: var(--white);
	border-right: 1px solid var(--border-grey-light);
	bottom: 0;
	color: var(--text-light);
	display: flex;
	flex-direction: column;
	font-size: 14px;
	left: 0;
	min-width: 240px;
	max-width: 240px;
	position: absolute;
	top: 0;
	transition: min-width .1s ease-in-out;
	z-index: 100;

	&.autoClose {
		&:not(.over) {
			align-items: center;
			min-width: 64px;
			max-width: 64px;
			// padding: 16px 0;
			.logoHeaderWrapper{
				svg{
					display: none;
				}
			}
			.scrollableArea{
				>.menuItemsWrapper{
					max-width: 64px;
					.sectionTitle{
						display: none;
					}
					.navItem{
						max-width: 64px;
						i{
							margin-right: 0;
						}
						span{
							max-width: 0;
						}
						.expander{
							display: none;
						}
					}
				}
			}

			.footerSidebar{
				align-items: center;
				display: flex;
				flex-direction: column;
				.dropDownTrigger{
					.uiTeamDropDown{
						border: 0;
						padding: 0;
						width: 40px;
						.uiTeamItem{
							.team{
								display: none;
							}
						}
						.teamDropdownTrigger {
							display: none;
						}
					}
				}
			}
		}

		&.over {
			min-width: 270px;

			.logoHeaderWrapper{
				svg{
					transform: rotate(180deg);
				}
			}
		}
	}	

	.scrollableArea {
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: auto;

		>.menuItemsWrapper {
			flex: 1;

			.sectionTitle {
				color: var(--text-inactive);
				font-size: 12px;
				font-weight: 600;
				letter-spacing: 1.5px;
				margin: 32px 0 8px 16px;
				text-transform: uppercase;
			}
			> .navItem {
				&.active {
					&::before{
						content: "";
						display: inline-block;
						left: 0;
						position: absolute;
						top: 0;
						bottom: 0;
						width: 6px;
						background-color: var(--primary-color);
					}
				}
			}
			.navItem {
				align-items: center;
				cursor: pointer;
				display: flex;
				font-weight: 500;
				padding: 15px 20px;

				&.expanded{
					& + .navSubItems{
						max-height: 500px;
						transition: max-height .25s ease-in;
						.navItem{
							&.active,
							&:hover{
								background: unset;
								border-right: unset;
								color: var(--primary-color);
								font-size: 14px;
							}
						}
					}
				}

				& + .navSubItems{
					border-left: 1px solid var(--primary-color-100);
					margin-left: 32px;
					max-height: 0;
					overflow: hidden;
					transition: max-height .25s ease-out;
				}				

				&.space {
					margin-bottom: 16px;
				}

				&.active {
					background: var(--primary-color-100);
					position: relative;
					span{
						color: var(--text-standard);
						font-weight: 600;
					}
				}

				&:hover {
					color: var(--text-standard);
					font-weight: 600;

					i {
						color: var(--primary-color);
					}
				}

				i {
					margin-right: 16px;

					&.active {
						color: var(--primary-color);
					}
				}

				span {
					display: inline-block;
					flex: 1;
					max-width: 100%;
					overflow: hidden;
					transition: max-width .1s ease-in-out;
					white-space: nowrap;
				}
			}
		}
	}

	.footerSidebar {
		padding: 16px 12px 0;

		&.scrollShadow {
			box-shadow: 0 -4px 20px rgba(0, 0, 0, .05);
		}

		.dropDownTrigger {
			cursor: pointer;

			.uiTeamDropDown {
				align-items: center;
				border: 1px solid var(--border-grey-light);
				border-radius: 10px;
				cursor: pointer;
				display: flex;
				height: 56px;
				justify-content: center;
				padding: 8px;

				.uiTeamItem {
					align-items: center;
					display: flex;
					flex: 1;
					gap: 16px;
					overflow: hidden;

					.teamPicture {
						height: 40px;
						width: 40px;
					}

					.team {
						display: flex;
						flex-direction: column;
						overflow: hidden;

						.teamName,
						.teamDesc {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.teamName {
							color: var(--text-standard);
							font-weight: 600;
						}

						.teamDesc {
							color: var(--text-light);
						}
					}
				}

				.teamDropdownTrigger {
					color: var(--text-standard);
					font-size: 12px !important;
					margin-left: 8px;
				}
			}
		}
	}
}